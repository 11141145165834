import React, { useState } from 'react';
import NamedLink from '../NamedLink/NamedLink';
import css from './CustomListingCard.module.css';

const CustomListingCard = props => {
  const { title, location, isFavourite, background, hotel, price } = props;

  return (
    <div className={css.cardbody}>
      <NamedLink name="ListingDetailPage">
        <div className={css.cardimage}>
          <div className={css.favourite}>
            {isFavourite ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M1.67344 10.5634L8.02617 16.4943C8.28984 16.7404 8.63789 16.8775 9 16.8775C9.36211 16.8775 9.71016 16.7404 9.97383 16.4943L16.3266 10.5634C17.3953 9.56852 18 8.17282 18 6.71383V6.50993C18 4.05251 16.2246 1.9572 13.8023 1.5529C12.1992 1.28571 10.568 1.80954 9.42188 2.95563L9 3.37751L8.57812 2.95563C7.43203 1.80954 5.80078 1.28571 4.19766 1.5529C1.77539 1.9572 0 4.05251 0 6.50993V6.71383C0 8.17282 0.604687 9.56852 1.67344 10.5634Z"
                  fill="#06C167"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
              >
                <path
                  d="M7.93828 15.4625L7.85039 15.3817L1.69102 9.66174C0.611719 8.65979 0 7.25354 0 5.78049V5.66447C0 3.18947 1.75781 1.06604 4.19062 0.601973C5.57578 0.334785 6.99258 0.654707 8.12109 1.44924C8.4375 1.67424 8.73281 1.93439 9 2.23322C9.14766 2.06447 9.30586 1.90979 9.47461 1.76564C9.60469 1.65314 9.73828 1.54768 9.87891 1.44924C11.0074 0.654707 12.4242 0.334785 13.8094 0.598457C16.2422 1.06252 18 3.18947 18 5.66447V5.78049C18 7.25354 17.3883 8.65979 16.309 9.66174L10.1496 15.3817L10.0617 15.4625C9.77344 15.7297 9.39375 15.8809 9 15.8809C8.60625 15.8809 8.22656 15.7332 7.93828 15.4625ZM8.40586 4.10002C8.3918 4.08947 8.38125 4.07541 8.3707 4.06135L7.74492 3.35822L7.74141 3.35471C6.9293 2.44416 5.70234 2.02932 4.50703 2.25783C2.86875 2.57072 1.6875 3.99807 1.6875 5.66447V5.78049C1.6875 6.78244 2.10586 7.74221 2.84063 8.42424L9 14.1442L15.1594 8.42424C15.8941 7.74221 16.3125 6.78244 16.3125 5.78049V5.66447C16.3125 4.00158 15.1312 2.57072 13.4965 2.25783C12.3012 2.02932 11.0707 2.44768 10.2621 3.35471C10.2621 3.35471 10.2621 3.35471 10.2586 3.35822C10.2551 3.36174 10.2586 3.35822 10.2551 3.36174L9.6293 4.06486C9.61875 4.07893 9.60469 4.08947 9.59414 4.10354C9.43594 4.26174 9.22148 4.34963 9 4.34963C8.77852 4.34963 8.56406 4.26174 8.40586 4.10354V4.10002Z"
                  fill="#06C167"
                />
              </svg>
            )}
          </div>
          <div className={css.location}>
            <div className={css.locationcontent}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="11"
                viewBox="0 0 9 11"
                fill="none"
              >
                <g clipPath="url(#clip0_79_2969)">
                  <path
                    d="M5.05547 10.725C6.25781 9.3457 9 6.00274 9 4.125C9 1.84766 6.98438 0 4.5 0C2.01562 0 0 1.84766 0 4.125C0 6.00274 2.74219 9.3457 3.94453 10.725C4.23281 11.0537 4.76719 11.0537 5.05547 10.725ZM4.5 2.75C4.89782 2.75 5.27936 2.89487 5.56066 3.15273C5.84196 3.41059 6 3.76033 6 4.125C6 4.48967 5.84196 4.83941 5.56066 5.09727C5.27936 5.35513 4.89782 5.5 4.5 5.5C4.10218 5.5 3.72064 5.35513 3.43934 5.09727C3.15804 4.83941 3 4.48967 3 4.125C3 3.76033 3.15804 3.41059 3.43934 3.15273C3.72064 2.89487 4.10218 2.75 4.5 2.75Z"
                    fill="#227667"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_79_2969">
                    <rect width="9" height="11" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className={css.locationtext}>Location</span>
            </div>
          </div>
        </div>
      </NamedLink>
      <div className={css.cardcontent}>
        <NamedLink name="ListingDetailPage" className={css.cardtitle}>
          {title}
        </NamedLink>
        <div className={css.cardhotel}>{hotel}</div>
        <div className={css.cardprice}>{price}</div>
      </div>
    </div>
  );
};

export default CustomListingCard;
