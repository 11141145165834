import React, { useState } from 'react';
import css from './SectionLiveDemo.module.css';
import { PrimaryLightButton } from '../Button/Button';

const SectionLiveDemo = () => {
  return (
    <div className={css.sectionbody}>
      <div className={css.sectionleft}>
        <div className={css.sectionleftimage}></div>
      </div>
      <div className={css.sectionright}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g clipPath="url(#clip0_81_2886)">
            <path
              d="M13.1641 5.625L20 12.8906L26.836 5.625H13.1641ZM29.9922 7.74219L24.336 13.75H34.4141L29.9922 7.74219ZM33.8985 17.5H20H6.10159L20 32.8359L33.8985 17.5ZM5.58597 13.75H15.6641L10.0078 7.74219L5.58597 13.75ZM39.5157 16.8828L21.3907 36.8828C21.0391 37.2734 20.5313 37.5 20 37.5C19.4688 37.5 18.9688 37.2734 18.6094 36.8828L0.484404 16.8828C-0.117158 16.2188 -0.164033 15.2266 0.367217 14.5078L9.11722 2.63281C9.46878 2.15625 10.0313 1.86719 10.625 1.86719H29.375C29.9688 1.86719 30.5313 2.14844 30.8828 2.63281L39.6328 14.5078C40.1641 15.2266 40.1094 16.2188 39.5157 16.8828Z"
              fill="#227667"
            />
          </g>
          <defs>
            <clipPath id="clip0_81_2886">
              <rect width="40" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div className={css.righteffect}>
          <div className={css.effect1}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="220"
              height="164"
              viewBox="0 0 220 164"
              fill="none"
            >
              <path
                d="M126.025 11.9568C129.047 14.4309 131.999 16.8995 134.802 19.4962C137.673 22.0984 140.476 24.6951 143.198 27.4253C145.877 30.1055 148.457 32.8712 150.936 35.7222C153.439 38.575 155.861 41.5613 158 44.5953C162.411 50.7437 166.159 57.3295 168.682 64.3784C169.896 67.9224 170.776 71.5567 171.322 75.2815C171.867 79.0293 171.915 82.878 171.749 86.7799C172.882 83.0238 173.465 79.1547 173.498 75.1725C173.531 71.1903 173.002 67.2339 171.976 63.3784C170 55.6034 166.231 48.3869 161.628 42.0137C160.497 40.387 159.217 38.8884 158.011 37.3257L156.09 35.0778C155.45 34.3285 154.734 33.6432 154.094 32.8939C151.379 30.0711 148.513 27.3994 145.495 24.879C142.527 22.3159 139.403 19.9504 136.124 17.7824C132.867 15.6393 129.599 13.6353 126.025 11.9568Z"
                fill="#27323D"
              />
              <path
                d="M169.678 83.7558C168.287 84.5555 166.955 85.4996 165.687 86.5186C164.418 87.5377 163.283 88.6371 162.143 89.806C159.868 92.0743 157.785 94.5675 155.899 97.2159C155.026 98.5456 154.083 99.8698 153.274 101.274C152.867 102.011 152.395 102.674 152.057 103.416L150.91 105.563C150.485 106.229 150.126 106.946 149.833 107.716L148.755 109.868C148.417 110.611 148.149 111.359 147.88 112.107L147.011 114.275L146.85 114.542C146.839 114.681 146.764 114.745 146.764 114.745L146.587 115.221L146.094 116.161C145.808 116.838 145.476 117.511 145.097 118.18C144.432 119.526 143.698 120.867 142.963 122.207C141.564 124.894 139.967 127.425 138.365 130.026C136.763 132.627 134.963 135.072 133.158 137.587C131.289 140.027 129.292 142.317 127.139 144.805C128.589 144.149 130.108 143.5 131.504 142.63C132.162 142.263 132.895 141.831 133.559 141.393L135.56 139.942C138.091 137.974 140.506 135.716 142.653 133.298C144.869 130.885 146.819 128.312 148.571 125.583C149.471 124.209 150.325 122.831 151.132 121.45C151.515 120.734 151.899 119.995 152.285 119.233L152.858 118.16L152.955 117.818L153.121 117.481C153.207 117.278 153.213 117.209 153.287 117.145L153.379 116.872C153.91 115.446 154.447 113.95 155.048 112.529C155.648 111.108 156.18 109.682 156.775 108.33C157.977 105.488 159.237 102.791 160.578 99.9598C161.911 97.2214 163.312 94.5117 164.781 91.8305C166.319 89.1548 167.932 86.4151 169.678 83.7558Z"
                fill="#27323D"
              />
              <path
                d="M117.744 8.7883C122.684 15.3277 127.79 21.5306 132.896 27.7335C138.002 33.9364 143.039 40.1339 147.931 46.3899C152.754 52.6405 157.502 58.9551 161.522 65.6321C163.564 69.0081 165.393 72.4372 166.788 76.042C168.113 79.6414 168.931 83.4806 168.262 87.5527C169.307 85.8171 169.962 83.7013 170.195 81.6223C170.433 79.4739 170.319 77.3676 170.066 75.2505C169.42 71.0054 168.057 66.9836 166.401 63.1486C165.964 62.1822 165.525 61.239 165.085 60.3189C164.598 59.3952 164.111 58.4715 163.624 57.5478C162.649 55.7236 161.599 53.9634 160.479 52.1978C159.355 50.4785 158.209 48.7573 157.039 47.0344C155.815 45.4004 154.592 43.7665 153.369 42.1325C148.284 35.6517 142.745 29.6246 136.82 24.0565C133.895 21.2405 130.82 18.5525 127.665 15.998C126.088 14.7092 124.461 13.4631 122.785 12.2597C121.155 11.0598 119.474 9.90273 117.744 8.7883Z"
                fill="#27323D"
              />
              <path
                d="M103.776 23.7731C104.688 24.0311 105.573 24.3335 106.431 24.6805C107.289 25.0274 108.123 25.3957 108.931 25.7854C110.59 26.6146 112.175 27.5079 113.684 28.4652C116.75 30.3834 119.585 32.5632 122.334 34.946C125.019 37.2539 127.618 39.7648 130.073 42.3342C132.592 44.9786 134.898 47.6761 137.192 50.5126C141.786 56.116 146.08 61.9756 150.219 68.0328C151.216 69.5791 152.358 71.0668 153.355 72.6131L154.923 74.9032C155.421 75.6879 155.943 76.4513 156.491 77.1934C157.488 78.7397 158.555 80.2914 159.622 81.8432C160.663 83.4163 161.753 84.9698 162.891 86.5039C162.431 84.6501 161.922 82.8159 161.365 81.0012C160.807 79.2097 160.109 77.4073 159.476 75.6798C158.848 73.8828 158.075 72.1444 157.303 70.406C156.951 69.5396 156.531 68.6677 156.104 67.8652C155.706 66.9951 155.308 66.125 154.911 65.2549C151.527 58.488 147.415 52.0835 142.57 46.1108L141.61 44.9868L140.644 43.9324C140.073 43.1885 139.427 42.5087 138.787 41.7594L136.78 39.7144C136.065 39.0292 135.35 38.3439 134.629 37.7281C131.832 35.0619 128.805 32.6573 125.542 30.5838C122.279 28.5102 118.786 26.6982 115.111 25.4312C113.273 24.7977 111.424 24.3031 109.495 23.9421C107.63 23.656 105.615 23.4979 103.776 23.7731Z"
                fill="#27323D"
              />
              <path
                d="M87.3293 20.4541C88.2912 20.6693 89.2475 20.9541 90.1985 21.3083L91.6194 21.9091C92.0254 22.0808 92.4954 22.3274 92.9014 22.499L94.2474 23.1639L95.588 23.8982C96.3891 24.3805 97.2596 24.8682 98.1247 25.4254L100.581 27.0862L102.962 28.811C104.554 29.9145 106.054 31.2905 107.639 32.4635L108.755 33.3899C109.145 33.77 109.47 34.0752 109.866 34.3858L112.086 36.3776L113.197 37.3735C113.545 37.6805 113.892 38.0107 114.237 38.364L116.378 40.4893C119.244 43.1609 121.95 46.0996 124.73 48.9742L125.248 49.5041L125.691 50.0981L126.726 51.1581L128.717 53.4115L130.707 55.6648L131.673 56.7193L132.628 57.9127C133.909 59.4114 135.189 60.91 136.534 62.4835C137.814 63.9821 139.165 65.4862 140.37 67.0489C141.647 68.5938 142.924 70.1387 144.201 71.6837C145.407 73.2463 146.757 74.7504 148.032 76.3185C150.513 79.4492 152.994 82.58 155.689 85.6576C153.877 82.02 151.84 78.5746 149.809 75.0596C148.758 73.2995 147.628 71.6728 146.502 69.9766C145.425 68.2609 144.3 66.5648 143.126 64.8881C142.561 64.0748 141.996 63.2615 141.43 62.4481L139.595 59.9972C138.395 58.3651 137.12 56.797 135.85 55.1594C135.555 54.7634 135.26 54.3674 134.964 53.9714L133.934 52.842L131.949 50.5192L129.965 48.1963L129.004 47.0723L128.486 46.5424L127.974 45.9429L123.699 41.6228C122.279 40.1133 120.774 38.8068 119.274 37.4308L116.989 35.3641C116.598 35.0071 116.205 34.6733 115.81 34.3627L114.624 33.4308L112.184 31.5616C111.789 31.251 111.4 30.8709 110.999 30.6298L109.739 29.7619L107.149 28.0208C106.359 27.3996 105.419 26.9064 104.548 26.4186L101.873 24.8805L99.1111 23.5454C98.6411 23.2988 98.2405 23.0576 97.6956 22.8751L96.2692 22.3437L94.8428 21.8124C94.3442 21.6335 93.8437 21.4777 93.3415 21.3451C91.4287 20.7756 89.3606 20.4037 87.3293 20.4541Z"
                fill="#27323D"
              />
              <path
                d="M75.7757 25.1413C81.7388 30.223 87.943 34.9041 94.1581 39.4463C100.368 44.0579 106.658 48.5361 112.809 53.0034C119.029 57.4761 125.245 62.0182 131.096 66.7416C134.059 69.0713 136.942 71.5345 139.6 74.1897C140.961 75.5548 142.183 76.909 143.324 78.3968C144.391 79.9485 145.313 81.5588 145.717 83.5479C145.932 82.5861 145.875 81.533 145.748 80.4744C145.593 79.4835 145.343 78.5085 144.999 77.5494C144.312 75.608 143.337 73.7838 142.281 72.0931C140.1 68.7062 137.544 65.6395 134.833 62.7703C129.341 57.0265 123.234 52.0034 116.88 47.4504C110.531 42.8278 103.936 38.6752 97.0995 34.9232C93.6813 33.0472 90.1882 31.2353 86.6788 29.6317C83.1053 27.9533 79.5209 26.4138 75.7757 25.1413Z"
                fill="#27323D"
              />
              <path
                d="M65.0662 30.6652L84.8587 42.1446C88.1592 44.0347 91.433 45.9693 94.68 47.9484C97.9537 49.883 101.153 51.8816 104.346 53.9497C110.669 58.011 116.906 62.2752 122.757 66.9986C125.715 69.3977 128.528 71.8555 131.111 74.5748C133.769 77.2301 136.197 80.1469 138.175 83.448C137.069 79.7259 135.026 76.35 132.829 73.1716C130.492 69.9822 127.851 67.1185 125.059 64.3829C119.471 58.981 113.261 54.3694 106.736 50.2223C105.094 49.1614 103.45 48.1469 101.801 47.1787C100.129 46.2087 98.4579 45.2387 96.7862 44.2686C93.4357 42.4212 90.0066 40.6842 86.499 39.0575C83.0377 37.4344 79.5228 35.9004 75.9543 34.4553C72.3535 33.1243 68.7583 31.7237 65.0662 30.6652Z"
                fill="#27323D"
              />
              <path
                d="M53.1896 34.1347C55.1405 34.2178 57.0587 34.7178 58.9769 35.2178C59.4329 35.3468 59.8888 35.4758 60.3447 35.6048L61.0981 35.8037C61.3225 35.9145 61.5486 36.0021 61.7766 36.0666L64.4959 37.0489L67.2042 38.1702C67.6797 38.3473 68.1552 38.5244 68.5612 38.696L69.9072 39.3609L72.5406 40.5462C73.4166 40.9644 74.2872 41.4522 75.1577 41.9399L77.7108 43.2587C78.5814 43.7464 79.3824 44.2287 80.2475 44.7859L82.7202 46.2382C83.1902 46.4848 83.5908 46.7259 83.9913 46.9671L85.257 47.7654L90.1111 50.9425L94.8793 54.3226C95.6695 54.9438 96.5401 55.4316 97.3302 56.0528L99.6367 57.8416L104.325 61.3552L113.459 68.7829C114.943 70.0645 116.451 71.3478 117.981 72.633L120.212 74.4858C121.003 75.1071 121.793 75.7283 122.508 76.4136C125.449 79.0212 128.46 81.6343 131.551 84.1138C129.146 80.919 126.584 77.9218 124.028 74.8551C123.388 74.1058 122.678 73.351 122.033 72.6712L119.967 70.4818C118.59 69.0223 117.212 67.5859 115.831 66.1726C114.406 64.7326 112.901 63.4261 111.395 62.1196C109.965 60.749 108.486 59.398 106.959 58.0665L102.288 54.3444L99.9174 52.4807C99.1272 51.8595 98.3316 51.3077 97.4665 50.7505L92.5647 47.29C90.8272 46.2682 89.1147 45.2251 87.4272 44.1606L86.0867 43.4263C85.6861 43.1852 85.2161 42.9386 84.7461 42.692L82.0595 41.2928L80.7135 40.628C80.313 40.3868 79.8429 40.1402 79.3675 39.9631L76.5951 38.7669L75.2436 38.1716C74.7681 37.9945 74.2981 37.7479 73.8172 37.6402L70.9589 36.6471L69.5326 36.1157C69.0303 35.9831 68.5512 35.8523 68.0953 35.7234L65.1512 34.9332L62.1853 34.4209C61.911 34.3528 61.6581 34.3096 61.4265 34.2915L60.6676 34.162C60.1581 34.1221 59.6717 34.0839 59.2085 34.0476C58.9342 33.9795 58.6813 33.9363 58.4497 33.9181L57.6799 33.9277L56.1459 33.8773L54.6759 33.9018C54.1841 33.9331 53.6868 34.0339 53.1896 34.1347Z"
                fill="#27323D"
              />
              <path
                d="M50.7273 40.5864C51.6501 40.7054 52.5479 40.8457 53.4207 41.0074C54.3399 41.1727 55.2554 41.3843 56.1673 41.6422C57.952 42.0619 59.7367 42.4815 61.441 43.0346C64.9886 44.1518 68.3863 45.397 71.7732 46.7812C78.4774 49.5441 84.9186 52.9855 91.0493 56.822L93.383 58.2634L95.6418 59.7688C96.4429 60.2511 97.169 60.7974 97.9006 61.2743L100.154 62.8492C101.606 63.9418 103.133 64.9704 104.58 66.1325L106.759 67.7715L108.862 69.4745C114.585 74.048 120.073 78.9525 125.625 83.932C121.497 77.736 116.555 72.1052 111.165 66.8587L109.147 64.9528L107.054 63.1108C105.618 61.8097 104.166 60.7171 102.655 59.4801C99.6867 57.2199 96.6432 55.0237 93.4444 53.0251C87.0467 49.0279 80.1722 45.7622 72.938 43.517C69.3209 42.3944 65.6875 41.4802 61.9519 40.9775C60.0868 40.6913 58.2163 40.4747 56.3348 40.3971C54.4534 40.3194 52.5665 40.3112 50.7273 40.5864Z"
                fill="#27323D"
              />
              <path
                d="M43.0991 39.7783C49.9422 42.5521 56.5606 45.518 63.0932 48.687C69.6525 51.8114 76.0529 55.1796 82.2944 58.7918C83.827 59.7509 85.3651 60.6405 86.8978 61.5997C88.4359 62.4893 89.9631 63.5179 91.4958 64.477C94.5611 66.3953 97.5515 68.3775 100.531 70.4988C106.426 74.6663 112.085 79.1649 117.803 83.8079C115.659 80.8433 113.225 77.996 110.706 75.3516C109.415 73.9919 108.118 72.7018 106.752 71.4061C106.1 70.7958 105.38 70.18 104.728 69.5696C104.008 68.9538 103.357 68.3435 102.636 67.7277L100.538 65.9552C99.8117 65.4089 99.0215 64.7877 98.2954 64.2414C96.8485 63.0793 95.3213 62.0507 93.869 60.958C92.3418 59.9294 90.8201 58.8313 89.2179 57.8668C87.6907 56.8382 86.0831 55.9431 84.481 54.9785C78.0615 51.2592 71.39 48.0794 64.4665 45.4391C60.9992 44.1884 57.5266 43.0072 53.9681 42.029C50.4041 41.1203 46.7707 40.2061 43.0991 39.7783Z"
                fill="#27323D"
              />
              <path
                d="M26.0257 47.1065C29.9021 47.5969 33.7408 48.2709 37.5418 49.1283C41.3501 49.8931 45.1012 50.7932 48.795 51.8286C52.5565 52.8926 56.2431 54.0207 59.8438 55.3517C61.6122 55.9798 63.45 56.6133 65.2074 57.3803L66.5644 57.9062L67.8518 58.4267C68.8027 58.7809 69.6842 59.1296 70.5602 59.5479L73.1242 60.7277C74.0002 61.146 74.8762 61.5642 75.7522 61.9825C76.6068 62.3758 77.4597 62.7922 78.3107 63.2318C79.1812 63.7195 80.0572 64.1378 80.8638 64.5506C84.2874 66.3571 87.5557 68.3612 90.824 70.3653C97.3443 74.5818 103.479 79.2575 109.517 84.2752C104.839 77.966 99.1276 72.3448 92.7954 67.5138C91.2096 66.3408 89.6238 65.1678 87.9631 64.0588C87.162 63.5765 86.3718 62.9553 85.5013 62.4676L82.9645 60.9403C79.4878 58.9199 75.9252 57.1025 72.3463 55.4935C71.4008 55.0698 70.4499 54.7156 69.5684 54.3668L68.2169 53.7714L66.7905 53.2401C64.9314 52.5816 63.0472 51.9445 61.1381 51.3287C57.3625 50.147 53.5224 49.1933 49.6179 48.4676C45.6902 47.7401 41.8156 47.2264 37.8552 46.9158C33.8839 46.7441 29.9766 46.6474 26.0257 47.1065Z"
                fill="#27323D"
              />
              <path
                d="M31.0591 56.0312C32.7429 55.9535 34.4105 56.0843 36.0726 56.2846C37.7347 56.4849 39.3859 56.8241 41.037 57.1634C42.6133 57.5666 44.2645 57.9059 45.8353 58.3786L48.2344 58.9863L50.6172 59.8023L53.0054 60.5489L55.3882 61.365C56.9481 61.9767 58.519 62.4495 60.0734 63.1307C61.5638 63.7369 63.1238 64.3487 64.6837 64.9604L69.2776 66.9985C70.8375 67.6102 72.317 68.3554 73.8715 69.0366L78.3904 71.1388C81.419 72.6347 84.453 74.0611 87.4815 75.557C88.9592 76.3254 90.4619 77.0725 91.9896 77.7982L94.2756 78.9562C95.0413 79.2959 95.7819 79.6569 96.4976 80.0393C99.5207 81.6047 102.549 83.1006 105.653 84.5324C103.101 82.3049 100.394 80.275 97.623 78.1701C96.3042 77.1579 94.8356 76.2737 93.4419 75.3255C92.025 74.3754 90.6082 73.4254 89.1913 72.4754C87.7281 71.5217 86.2486 70.7765 84.78 69.8923C83.3113 69.0081 81.7678 68.1879 80.2937 67.3732C78.8196 66.5585 77.2706 65.8078 75.7216 65.0572C74.1726 64.3065 72.6236 63.5558 71.0691 62.8746L66.3308 60.8951C64.7014 60.2779 63.0666 59.7302 61.5012 59.188C59.8718 58.5708 58.2261 58.1621 56.5163 57.6784L54.0531 56.9959L51.5096 56.4468C50.6867 56.2425 49.8389 56.0595 48.9661 55.8978L46.4116 55.4877C44.755 55.2179 43.0125 55.1512 41.2755 55.0149C40.4417 54.9495 39.5276 55.0176 38.6938 54.9522C37.8546 54.9563 36.9404 55.0244 36.0957 55.098C35.2565 55.1021 34.4064 55.2451 33.5563 55.3882C32.7062 55.5312 31.8506 55.7437 31.0591 56.0312Z"
                fill="#27323D"
              />
              <path
                d="M16.5071 57.0575C22.9157 60.9158 29.6621 64.0316 36.4249 66.9389C43.2803 69.8535 50.2289 72.4725 57.2705 74.7958C60.8181 75.913 64.3657 77.0302 67.9242 78.0084C69.7035 78.4975 71.4773 79.0561 73.262 79.4757C75.0894 79.9453 76.8936 80.413 78.6746 80.879C82.244 81.7182 85.8884 82.4934 89.5382 83.1991C93.188 83.9049 96.8487 84.4716 100.52 84.8995C97.0639 83.5098 93.5912 82.3286 90.124 81.0779C86.6568 79.8273 83.1092 78.7101 79.642 77.4594L69.149 73.9798L58.6506 70.5698L37.6592 63.6801C30.6335 61.4512 23.6078 59.2224 16.5071 57.0575Z"
                fill="#27323D"
              />
              <path
                d="M14.5151 84.2344C20.7494 83.1854 26.9672 82.3448 33.1633 81.7822C39.3593 81.2196 45.5391 80.8654 51.6861 80.9281C57.8331 80.9909 64.0278 81.3369 70.1148 82.1639C76.2768 82.9269 82.3203 84.3097 88.4918 85.8424C87.1513 85.1081 85.7521 84.2294 84.3366 83.5591C83.5941 83.2212 82.8516 82.8833 82.1786 82.5509L79.9457 81.6068L78.7918 81.1667L77.7019 80.8016L75.3886 79.991L73.1339 79.3248C72.3573 79.124 71.5808 78.9233 70.8042 78.7226C64.5578 77.2539 58.1124 76.5386 51.6875 76.4541C45.3266 76.4445 38.9166 77.0603 32.666 78.3177C29.5398 78.958 26.4491 79.7409 23.3937 80.6664C20.3774 81.6881 17.3557 82.7794 14.5151 84.2344Z"
                fill="#27323D"
              />
              <path
                d="M4.5616 89.0491C5.87221 88.3829 7.31087 87.8666 8.68006 87.3448C10.0706 86.848 11.4788 86.4225 12.9048 86.0683C15.7603 85.3135 18.6744 84.7032 21.5722 84.3013C24.4754 83.83 27.4318 83.5725 30.3827 83.3845C31.0884 83.3001 31.8136 83.2637 32.5584 83.2755C33.2586 83.2606 34.0338 83.1815 34.7341 83.1666L36.9738 83.1325L39.1386 83.1625C39.8834 83.1743 40.6281 83.1861 41.3729 83.1979C42.0677 83.2524 42.8374 83.2429 43.5322 83.2974C45.0663 83.3478 46.5308 83.3928 47.9845 83.5767L50.1384 83.7457L52.3618 83.9201C53.8209 84.0345 55.2691 84.2879 56.7227 84.4719C58.1818 84.5863 59.63 84.8397 61.0837 85.0236L65.4283 85.7839L69.7674 86.6136L74.1011 87.5128L74.4444 83.1355C74.1611 83.1832 74.0221 83.1723 73.8082 83.2254L73.3109 83.3262C72.9331 83.3898 72.5784 83.4552 72.2469 83.5224C71.5839 83.6568 70.9209 83.7912 70.2579 83.9256C68.9282 84.2408 67.6394 84.6291 66.3915 85.0904C63.8138 85.867 61.2839 86.9269 58.8875 88.0672C56.4911 89.2075 54.1532 90.4922 51.874 91.9213C49.6197 93.329 47.4276 94.8349 45.2978 96.4388C41.0594 99.6717 37.2107 103.285 33.6876 107.203C30.1645 111.121 26.9724 115.275 24.1809 119.67C27.6986 115.821 31.2803 112.047 35.1126 108.643C37.0218 106.882 38.9931 105.219 41.0267 103.654C42.0649 102.896 43.0389 102.064 44.0771 101.307C45.1152 100.549 46.1479 99.8611 47.2555 99.1091C51.4612 96.2931 55.9229 93.7769 60.555 91.7633C62.8692 90.7797 65.2439 89.9173 67.6789 89.1762C68.8732 88.8038 70.0639 88.4778 71.251 88.198C71.8677 88.06 72.4844 87.9219 73.1011 87.7839C73.3827 87.7594 73.666 87.7117 73.9512 87.6408L74.443 87.6095L74.7959 87.5673L87.3597 86.0361L75.0698 83.1845C69.2293 81.8875 63.2335 80.7881 57.269 80.1804C51.2351 79.5673 45.1794 79.2321 39.0746 79.5223C33.0447 79.7484 27.0353 80.6053 21.1213 82.0289C18.1731 82.7764 15.303 83.7164 12.5111 84.849C9.69776 85.9565 7.00707 87.2835 4.5616 89.0491Z"
                fill="#27323D"
              />
              <path
                d="M11.9043 96.125C16.802 95.1809 21.5717 94.087 26.3359 93.0625C31.1001 92.038 35.8643 91.0135 40.6122 90.1975C45.3601 89.3815 50.172 88.6404 54.9566 88.2467C57.3517 88.0151 59.7413 87.853 62.1949 87.7658C64.6431 87.7481 67.0858 87.7999 69.5926 87.9266C67.3011 86.8381 64.8379 86.1555 62.4333 85.6174C59.9593 85.0738 57.3885 84.8721 54.8817 84.7454C49.8682 84.492 44.8002 84.9333 39.8384 85.8025C34.9461 86.6771 30.0852 88.0435 25.4109 89.7042C23.0612 90.5453 20.7488 91.5057 18.4737 92.5856C16.2271 93.5978 13.9697 94.749 11.9043 96.125Z"
                fill="#27323D"
              />
              <path
                d="M68.5075 87.4924C66.2365 87.0346 63.9436 86.8548 61.6344 86.8834C59.3306 86.8425 57.005 87.0795 54.674 87.386C50.0815 88.0045 45.5788 89.2592 41.2465 91.0167C40.1607 91.4908 39.1443 91.9703 38.0585 92.4444C37.0367 92.9934 36.015 93.5425 34.9932 94.0915C33.9659 94.71 32.9442 95.259 31.981 95.9525C31.5368 96.2672 31.0232 96.5764 30.5736 96.9606L29.1663 97.9688C27.3039 99.4306 25.5751 100.973 23.9743 102.665C22.3735 104.357 20.9757 106.135 19.7059 108.062C23.5424 105.497 27.229 103.06 31.1077 100.847C33.0086 99.7848 34.9523 98.7721 36.9386 97.8094C37.955 97.3299 38.9018 96.8449 39.9127 96.4348C40.929 95.9553 41.9399 95.5452 42.9508 95.1351C46.9979 93.4485 51.1427 92.0026 55.3852 90.7974C59.6794 89.5263 64.0267 88.4692 68.5075 87.4924Z"
                fill="#27323D"
              />
              <path
                d="M79.121 85.8784C76.7151 86.249 74.3568 86.9029 71.9931 87.6263C69.697 88.3783 67.4382 89.2498 65.2166 90.2406C60.729 92.1956 56.4171 94.5838 52.4199 97.4162C51.4567 98.1096 50.488 98.8725 49.4499 99.63C48.5507 100.398 47.5821 101.161 46.6774 101.999C44.8042 103.6 43.0644 105.281 41.3832 107.107C38.1598 110.769 35.3206 114.88 33.3574 119.41C36.8424 115.978 40.247 112.68 43.8437 109.607C44.7428 108.838 45.6365 108.139 46.5357 107.371C47.4989 106.678 48.3981 105.909 49.2918 105.21C51.1755 103.773 53.0788 102.385 55.0015 101.044C58.8489 98.3399 62.7548 95.78 66.7942 93.3006C70.8337 90.8211 74.9372 88.4165 79.121 85.8784Z"
                fill="#27323D"
              />
              <path
                d="M93.079 85.2928C92.4904 85.6661 91.9128 85.9004 91.2657 86.1293L90.3297 86.4753L89.3992 86.7519C88.1799 87.1456 86.8965 87.4644 85.6132 87.7832C83.1214 88.3567 80.5656 88.8553 78.0098 89.3539C76.6624 89.5978 75.4648 89.7136 74.0371 90.0909C72.6575 90.4488 71.2955 90.8779 69.9513 91.3784C67.3574 92.3633 64.8751 93.7066 62.5209 95.1998C57.8766 98.261 53.8085 101.997 50.3386 106.129C46.8631 110.33 43.9163 114.923 41.7869 119.789C43.4844 117.755 45.2459 115.796 47.077 113.842C48.9026 111.958 50.7282 110.074 52.6123 108.334C56.4556 104.791 60.555 101.547 64.8833 98.9504C67.0181 97.5799 69.2756 96.4287 71.5861 95.4914C72.7414 95.0227 73.8913 94.6236 75.1052 94.2993L76.9607 93.8157L78.8912 93.268C81.5383 92.4969 84.1323 91.512 86.5341 90.3022C87.7698 89.7 89.0109 89.0284 90.124 88.2069C90.6698 87.7837 91.2175 87.3373 91.767 86.8677C92.0168 86.6543 92.2452 86.4159 92.4523 86.1525C92.6825 85.8909 92.9128 85.6293 93.079 85.2928Z"
                fill="#27323D"
              />
              <path
                d="M101.526 84.5594C99.8541 85.0807 98.2211 85.6983 96.6271 86.4122C94.9905 87.0761 93.3983 87.7668 91.8507 88.4843C88.7017 90.0084 85.6151 91.6305 82.5906 93.3507C79.5252 94.9978 76.5825 96.8642 73.6343 98.8001C72.163 99.7333 70.7556 100.741 69.3483 101.75C67.9142 102.802 66.5032 103.857 65.1154 104.913C63.7721 105.996 62.4288 107.079 61.08 108.232C59.7313 109.384 58.3825 110.537 57.1032 111.695C54.5338 114.15 52.0979 116.685 49.79 119.37C47.5461 122.131 45.4358 124.971 43.7206 128.122L42.4876 130.472C42.098 131.281 41.7546 132.093 41.4576 132.908C41.1089 133.79 40.9045 134.613 40.6252 135.499C40.4172 136.369 40.2342 137.216 40.0762 138.043C41.786 134.961 43.8105 132.324 46.1074 129.778C48.3349 127.226 50.685 124.893 53.1741 122.572C54.4534 121.414 55.7272 120.325 56.9315 119.231C58.2748 118.148 59.4846 116.985 60.8225 115.971C63.4165 113.798 66.0763 111.676 68.8019 109.606C71.4136 107.504 74.1642 105.413 76.9094 103.391L85.0864 97.1817C90.5822 93.0687 96.0781 88.9558 101.526 84.5594Z"
                fill="#27323D"
              />
              <path
                d="M109.786 83.5306C103.202 86.3697 96.916 89.8614 90.8221 93.5779C84.7227 97.3639 78.746 101.369 73.0839 105.819C67.4273 110.199 61.9519 114.942 57.0528 120.361C54.6755 123.04 52.3512 125.934 50.497 129.074C49.5379 130.607 48.7069 132.289 48.0842 133.988C47.3921 135.682 47.0366 137.541 47.0338 139.359C47.3131 138.472 47.5175 137.649 47.9303 136.842C48.1019 136.436 48.3431 136.036 48.5093 135.699C48.7273 135.297 48.9434 134.918 49.1578 134.562C50.0688 133.049 51.1114 131.639 52.2858 130.333C54.5242 127.642 56.9547 125.177 59.5882 122.796C64.7162 118.025 70.2338 113.635 75.8154 109.319C81.4026 104.933 87.0482 100.692 92.7634 96.4566C98.4786 92.2211 104.194 87.9855 109.786 83.5306Z"
                fill="#27323D"
              />
              <path
                d="M120.679 83.687C117.374 84.8259 114.186 86.2536 111.062 87.7563C107.963 89.2377 104.901 90.8384 101.877 92.5586C95.8316 95.9527 89.9925 99.6892 84.3595 103.768C83.0216 104.782 81.6088 105.859 80.196 106.937C78.8546 107.997 77.5113 109.08 76.1661 110.186C73.5381 112.497 70.9101 114.807 68.4741 117.343C66.0382 119.878 63.7358 122.494 61.6895 125.409C61.4484 125.81 61.2127 126.141 60.977 126.472L60.259 127.604C60.0178 128.005 59.7767 128.405 59.541 128.736L58.9565 129.949C58.5492 130.686 58.2004 131.567 57.8571 132.379C57.7713 132.582 57.6854 132.785 57.5996 132.988L57.4116 133.602C57.3289 134.062 57.225 134.497 57.0996 134.906C59.0764 131.985 61.432 129.583 63.9156 127.332C66.4047 125.01 68.9468 122.902 71.628 120.806C74.2647 118.682 76.944 116.609 79.666 114.585C81.0734 113.577 82.4112 112.564 83.7491 111.55C85.0869 110.536 86.4943 109.528 87.9016 108.52C93.3865 104.546 98.8769 100.503 104.367 96.4591C109.863 92.3461 115.364 88.1637 120.679 83.687Z"
                fill="#27323D"
              />
              <path
                d="M130.317 82.9021C123.989 86.041 118.034 89.7684 112.132 93.7097C106.306 97.587 100.665 101.758 95.1422 106.219C93.7971 107.325 92.4502 108.454 91.1014 109.607C89.7526 110.76 88.4039 111.912 87.1246 113.07C84.5606 115.456 81.9912 117.911 79.5498 120.515C77.1084 123.12 74.8005 125.806 72.7542 128.721C71.7256 130.248 70.7025 131.706 69.9464 133.324C69.0567 134.863 68.4341 136.561 68.084 138.352C68.4968 137.545 68.8401 136.733 69.381 136.076C69.6221 135.676 69.8578 135.345 70.163 135.019L70.945 133.962C72.0267 132.649 73.1761 131.364 74.3932 130.108C76.8236 127.642 79.3822 125.326 82.0102 123.015C84.5633 120.769 87.2554 118.533 89.8779 116.292C91.2231 115.186 92.5682 114.08 93.9133 112.973C95.2621 111.821 96.6054 110.738 97.9487 109.655L114.224 96.4603C119.672 92.064 125.056 87.5927 130.317 82.9021Z"
                fill="#27323D"
              />
              <path
                d="M138.443 82.6984C135.323 85.0403 132.198 87.4517 129.084 89.7241C125.894 92.0606 122.78 94.333 119.607 96.461C117.98 97.5917 116.365 98.5835 114.738 99.7143C113.122 100.706 111.501 101.767 109.821 102.684C108.205 103.676 106.531 104.523 104.851 105.44L102.31 106.639C101.877 106.815 101.444 106.991 101.011 107.166L99.7164 107.624L98.4167 108.151C97.8391 108.386 97.4058 108.561 96.8977 108.801C95.9313 109.238 94.9863 109.7 94.0626 110.187C92.2969 111.307 90.6008 112.432 89.0272 113.777C85.8215 116.321 83.0695 119.321 80.6486 122.557C76.0097 129.114 72.7004 136.544 70.9143 144.164C74.3993 137.167 78.4824 130.566 83.4142 124.731C85.9414 121.923 88.5967 119.266 91.6361 117.057C92.3105 116.481 93.1184 115.985 93.8568 115.484C94.2315 115.163 94.6647 114.988 95.0339 114.737C95.4031 114.486 95.8418 114.241 96.2056 114.06C97.033 113.612 97.8568 113.211 98.6769 112.856C99.0407 112.674 99.4739 112.499 99.8322 112.387L101.276 111.801C103.218 111.115 105.036 110.209 106.855 109.303C108.61 108.322 110.365 107.341 112.055 106.285C113.746 105.229 115.378 104.029 116.935 102.893C118.568 101.692 120.125 100.556 121.693 99.2811C124.733 96.7698 127.666 94.1337 130.492 91.3726C133.228 88.5811 135.964 85.7896 138.443 82.6984Z"
                fill="#27323D"
              />
              <path
                d="M147.978 83.2378C144.41 85.0552 141.028 87.1668 137.647 89.2785C134.358 91.3974 131.085 93.6108 127.828 95.9186C121.45 100.592 115.194 105.484 109.263 110.681C103.327 115.948 97.5836 121.44 92.3575 127.461C89.675 130.467 87.1955 133.558 84.908 136.874C83.799 138.535 82.7541 140.27 81.7786 142.011C81.2909 142.882 80.8672 143.827 80.4489 144.703C80.2023 145.173 80.0252 145.649 79.8481 146.124C79.6728 146.577 79.4957 147.052 79.3168 147.551C81.7377 144.315 84.4039 141.518 87.2091 138.732C89.9393 136.01 92.803 133.369 95.6667 130.727C101.394 125.444 107.319 120.316 113.175 115.182C119.036 109.98 124.966 104.782 130.757 99.5739C136.56 94.2266 142.426 88.9543 147.978 83.2378Z"
                fill="#27323D"
              />
              <path
                d="M153.703 83.3362C148.783 88.1236 143.997 92.9913 139.211 97.859C134.45 102.705 129.67 107.504 124.87 112.254C120.025 116.977 115.116 121.625 110.144 126.199C105.101 130.767 100.001 135.19 94.6915 139.598C100.928 136.731 106.814 132.998 112.379 128.891C117.806 124.772 123.056 120.221 127.976 115.434C132.833 110.548 137.45 105.457 141.827 100.161C143.927 97.4599 146.026 94.7583 148.061 91.9818C150.027 89.1999 151.999 86.3484 153.703 83.3362Z"
                fill="#27323D"
              />
              <path
                d="M161.511 83.5998C158.06 85.706 154.924 88.2563 151.846 90.951C148.833 93.7207 145.953 96.5708 143.329 99.7206C142.638 100.505 142.017 101.295 141.396 102.086C140.728 102.872 140.105 103.685 139.526 104.526L137.657 106.966L135.986 109.561C135.675 109.956 135.434 110.357 135.118 110.821L134.314 112.156L132.781 114.762L131.307 117.513L130.637 118.929C130.39 119.399 130.219 119.805 129.977 120.205C129.141 121.957 128.171 123.629 127.131 125.295C126.16 126.99 125.12 128.656 124.013 130.293C122.909 131.885 121.736 133.47 120.563 135.056C119.324 136.59 118.064 138.099 116.783 139.583C111.562 145.536 105.562 150.727 98.7299 154.945C106.41 152.402 113.419 147.708 119.318 142.019C122.268 139.175 124.961 136.03 127.457 132.731C129.883 129.426 132.048 125.89 133.946 122.194C134.193 121.724 134.439 121.254 134.616 120.779L135.276 119.502L136.6 116.88L138.058 114.337L138.792 112.997L139.521 111.726L141.048 109.189L142.709 106.733C143.191 105.932 143.812 105.141 144.37 104.276C144.921 103.481 145.473 102.685 146.025 101.889C148.376 98.6484 150.856 95.5572 153.405 92.4714C155.975 89.4107 158.677 86.4534 161.511 83.5998Z"
                fill="#27323D"
              />
              <path
                d="M173.002 85.063C171.74 86.0126 170.733 87.2619 169.795 88.5166C168.789 89.7659 167.985 91.101 167.181 92.4361C165.573 95.1064 164.163 97.9319 162.962 100.774C162.629 101.447 162.291 102.189 162.023 102.937L161.154 105.106C160.692 106.538 160.086 108.028 159.624 109.46C159.226 110.967 158.695 112.393 158.297 113.9C157.899 115.407 157.501 116.914 157.103 118.421L157.098 118.49L157.018 118.624L156.985 119.04L156.883 119.452C156.815 119.726 156.745 120.024 156.673 120.344C156.554 120.964 156.372 121.509 156.184 122.123C155.877 123.358 155.501 124.587 155.061 125.741C154.24 128.193 153.354 130.57 152.266 132.862L151.847 133.738L151.429 134.614C151.102 135.217 150.92 135.762 150.593 136.366C150.267 136.946 149.967 137.505 149.692 138.043C149.365 138.646 149.044 139.18 148.717 139.784C147.42 142.059 145.92 144.248 144.478 146.582C146.763 145.083 148.92 143.435 150.89 141.492C152.86 139.55 154.568 137.377 156.148 135.054C157.723 132.8 159.037 130.317 160.147 127.747C160.712 126.487 161.234 125.176 161.714 123.816C161.907 123.132 162.165 122.523 162.358 121.839C162.433 121.472 162.509 121.105 162.584 120.738L162.761 120.263C162.778 120.054 162.794 119.846 162.88 119.643L162.794 119.846C163.188 118.385 163.582 116.925 163.977 115.464L165.159 111.083L167.449 102.384C168.238 99.4632 169.072 96.546 169.953 93.6323C170.808 90.7632 171.667 87.8245 173.002 85.063Z"
                fill="#27323D"
              />
              <path
                d="M83.9109 86.3212C95.3889 85.2641 106.898 84.6988 118.38 84.4809C129.862 84.263 141.312 84.462 152.776 85.3612C158.479 85.7386 164.16 86.394 169.755 87.2523C172.563 87.7055 175.341 88.2264 178.092 88.815C180.888 89.4072 183.608 90.0866 186.251 90.8531C188.911 91.691 191.567 92.5984 194.067 93.7033C196.567 94.8082 198.981 96.1161 201.101 97.6106C203.215 99.1746 205.035 100.925 206.422 102.852C207.734 104.842 208.538 107.072 208.78 109.328L215.629 108.467C215.126 105.072 213.709 101.745 211.682 99.0698C209.655 96.3941 207.092 94.3055 204.428 92.6284C201.838 90.8873 199.001 89.6162 196.218 88.559C193.371 87.4268 190.501 86.5726 187.621 85.8573C184.786 85.1689 181.918 84.5944 179.018 84.1339C176.122 83.6271 173.216 83.236 170.302 82.9608C164.551 82.3 158.704 81.9812 152.91 81.8763C141.332 81.5274 129.764 81.9483 118.308 82.7275C106.782 83.5013 95.299 84.6279 83.9163 86.2518L83.9109 86.3212Z"
                fill="#27323D"
              />
            </svg>
          </div>
          <div className={css.effect2}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="140"
              viewBox="0 0 100 140"
              fill="none"
            >
              <path
                d="M101.345 99.1173C102.076 97.0151 102.776 94.9364 103.365 92.841C103.986 90.7221 104.575 88.6267 105.086 86.4911C105.591 84.3923 106.022 82.2825 106.38 80.1614C106.749 78.0325 107.039 75.8634 107.178 73.7565C107.497 69.4636 107.365 65.1593 106.505 60.9996C106.045 58.9249 105.398 56.9067 104.562 54.9451C103.719 52.9729 102.606 51.1016 101.373 49.2689C103.031 50.7404 104.455 52.432 105.647 54.3436C106.839 56.2552 107.752 58.3231 108.394 60.4918C109.733 64.8375 110.041 69.4524 109.696 73.9114C109.629 75.0363 109.452 76.1443 109.33 77.2608L109.064 78.9229C108.976 79.477 108.832 80.0226 108.743 80.5767C108.264 82.7549 107.666 84.9057 106.949 87.0291C106.27 89.1581 105.457 91.2385 104.511 93.2703C103.568 95.2836 102.578 97.2331 101.345 99.1173Z"
                fill="#27323D"
              />
              <path
                d="M101.345 99.1173C102.076 97.0151 102.776 94.9364 103.365 92.841C103.986 90.7221 104.575 88.6267 105.086 86.4911C105.591 84.3923 106.022 82.2825 106.38 80.1614C106.749 78.0325 107.039 75.8634 107.178 73.7565C107.497 69.4636 107.365 65.1593 106.505 60.9996C106.045 58.9249 105.398 56.9067 104.562 54.9451C103.719 52.9729 102.606 51.1016 101.373 49.2689C103.031 50.7404 104.455 52.432 105.647 54.3436C106.839 56.2552 107.752 58.3231 108.394 60.4918C109.733 64.8375 110.041 69.4524 109.696 73.9114C109.629 75.0363 109.452 76.1443 109.33 77.2608L109.064 78.9229C108.976 79.477 108.832 80.0226 108.743 80.5767C108.264 82.7549 107.666 84.9057 106.949 87.0291C106.27 89.1581 105.457 91.2385 104.511 93.2703C103.568 95.2836 102.578 97.2331 101.345 99.1173Z"
                fill="#27323D"
              />
              <path
                d="M101.267 51.3553C100.357 51.3876 99.434 51.3327 98.5193 51.2223C97.6047 51.1119 96.7309 50.9226 95.8339 50.7014C94.0632 50.2909 92.3191 49.7142 90.6246 49.0032C89.8094 48.6241 88.9622 48.2686 88.1558 47.8342C87.7411 47.601 87.3174 47.4233 86.9347 47.1666L85.7456 46.4755C85.3433 46.282 84.9576 46.0438 84.5885 45.7608L83.4314 45.0462C83.0487 44.7895 82.6979 44.5093 82.3472 44.2291L81.2861 43.4439L81.1295 43.3634C81.0832 43.2997 81.028 43.2913 81.028 43.2913L80.8019 43.1151L80.2857 42.8098C79.9474 42.5693 79.5878 42.3445 79.2069 42.1354C78.4877 41.6858 77.7365 41.2598 76.9853 40.8337C75.5149 39.9581 73.9948 39.2167 72.4516 38.4435C70.9083 37.6703 69.3154 37.0314 67.6992 36.3606C66.0743 35.7452 64.4316 35.2407 62.6554 34.6875C63.5503 34.5679 64.4772 34.4248 65.4096 34.4243C65.8366 34.404 66.3188 34.392 66.769 34.4036L68.1658 34.502C69.9719 34.6909 71.8067 35.0543 73.5597 35.5756C75.3447 36.0734 77.048 36.729 78.7016 37.519C79.5429 37.9114 80.3629 38.3196 81.1616 38.7434C81.5579 38.9737 81.962 39.2148 82.3738 39.4664L82.9684 39.8119L83.1162 39.9479L83.296 40.0603C83.3974 40.1324 83.4206 40.1643 83.4758 40.1726L83.6004 40.2767C84.2787 40.8052 84.9802 41.3656 85.6905 41.8706C86.4008 42.3756 87.0792 42.9041 87.7663 43.3772C89.187 44.3872 90.5934 45.3098 92.078 46.2728C93.5318 47.1932 95.0099 48.0795 96.5123 48.9317C98.0467 49.7603 99.6363 50.5973 101.267 51.3553Z"
                fill="#27323D"
              />
              <path
                d="M101.267 51.3553C100.357 51.3876 99.434 51.3327 98.5193 51.2223C97.6047 51.1119 96.7309 50.9226 95.8339 50.7014C94.0632 50.2909 92.3191 49.7142 90.6246 49.0032C89.8094 48.6241 88.9622 48.2686 88.1558 47.8342C87.7411 47.601 87.3174 47.4233 86.9347 47.1666L85.7456 46.4755C85.3433 46.282 84.9576 46.0438 84.5885 45.7608L83.4314 45.0462C83.0487 44.7895 82.6979 44.5093 82.3472 44.2291L81.2861 43.4439L81.1295 43.3634C81.0832 43.2997 81.028 43.2913 81.028 43.2913L80.8019 43.1151L80.2857 42.8098C79.9474 42.5693 79.5878 42.3445 79.2069 42.1354C78.4877 41.6858 77.7365 41.2598 76.9853 40.8337C75.5149 39.9581 73.9948 39.2167 72.4516 38.4435C70.9083 37.6703 69.3154 37.0314 67.6992 36.3606C66.0743 35.7452 64.4316 35.2407 62.6554 34.6875C63.5503 34.5679 64.4772 34.4248 65.4096 34.4243C65.8366 34.404 66.3188 34.392 66.769 34.4036L68.1658 34.502C69.9719 34.6909 71.8067 35.0543 73.5597 35.5756C75.3447 36.0734 77.048 36.729 78.7016 37.519C79.5429 37.9114 80.3629 38.3196 81.1616 38.7434C81.5579 38.9737 81.962 39.2148 82.3738 39.4664L82.9684 39.8119L83.1162 39.9479L83.296 40.0603C83.3974 40.1324 83.4206 40.1643 83.4758 40.1726L83.6004 40.2767C84.2787 40.8052 84.9802 41.3656 85.6905 41.8706C86.4008 42.3756 87.0792 42.9041 87.7663 43.3772C89.187 44.3872 90.5934 45.3098 92.078 46.2728C93.5318 47.1932 95.0099 48.0795 96.5123 48.9317C98.0467 49.7603 99.6363 50.5973 101.267 51.3553Z"
                fill="#27323D"
              />
              <path
                d="M98.2729 103.138C98.732 98.4976 99.3709 93.9696 100.01 89.4417C100.649 84.9137 101.256 80.4092 101.775 75.9199C102.263 71.454 102.696 66.9799 102.669 62.5495C102.66 60.3067 102.532 58.1025 102.143 55.9437C101.721 53.8085 100.983 51.7104 99.4569 49.9466C100.475 50.47 101.417 51.2938 102.144 52.2269C102.893 53.1919 103.46 54.2425 103.963 55.3402C104.904 57.5826 105.432 59.9327 105.763 62.2812C105.837 62.8788 105.903 63.4658 105.962 64.0422C105.999 64.6342 106.036 65.2262 106.074 65.8183C106.14 66.9917 106.152 68.1568 106.132 69.3454C106.096 70.5127 106.049 71.6879 105.992 72.8709C105.882 74.0271 105.773 75.1833 105.663 76.3394C105.117 80.995 104.217 85.5684 102.994 90.0363C102.41 92.2743 101.716 94.4957 100.944 96.6768C100.562 97.7727 100.143 98.863 99.6868 99.9477C99.2523 101.017 98.781 102.08 98.2729 103.138Z"
                fill="#27323D"
              />
              <path
                d="M98.2729 103.138C98.732 98.4976 99.3709 93.9696 100.01 89.4417C100.649 84.9137 101.256 80.4092 101.775 75.9199C102.263 71.454 102.696 66.9799 102.669 62.5495C102.66 60.3067 102.532 58.1025 102.143 55.9437C101.721 53.8085 100.983 51.7104 99.4569 49.9466C100.475 50.47 101.417 51.2938 102.144 52.2269C102.893 53.1919 103.46 54.2425 103.963 55.3402C104.904 57.5826 105.432 59.9327 105.763 62.2812C105.837 62.8788 105.903 63.4658 105.962 64.0422C105.999 64.6342 106.036 65.2262 106.074 65.8183C106.14 66.9917 106.152 68.1568 106.132 69.3454C106.096 70.5127 106.049 71.6879 105.992 72.8709C105.882 74.0271 105.773 75.1833 105.663 76.3394C105.117 80.995 104.217 85.5684 102.994 90.0363C102.41 92.2743 101.716 94.4957 100.944 96.6768C100.562 97.7727 100.143 98.863 99.6868 99.9477C99.2523 101.017 98.781 102.08 98.2729 103.138Z"
                fill="#27323D"
              />
              <path
                d="M87.0904 100.107C87.4554 99.7084 87.7944 99.2964 88.1072 98.871C88.42 98.4455 88.7145 98.0173 88.9906 97.5863C89.5486 96.6873 90.0514 95.78 90.4991 94.8643C91.4159 93.0172 92.144 91.1131 92.7706 89.1369C93.3883 87.216 93.9046 85.2231 94.3337 83.2453C94.7716 81.212 95.0904 79.2175 95.3629 77.1592C95.9309 73.0744 96.2783 68.9562 96.4923 64.7893C96.5182 63.7434 96.6312 62.6824 96.6571 61.6365L96.7396 60.06C96.7487 59.5318 96.7761 59.0063 96.822 58.4836C96.8479 57.4377 96.9058 56.3683 96.9637 55.2989C97.0032 54.2267 97.0718 53.1494 97.1695 52.0671C97.4941 53.1 97.7896 54.1379 98.0561 55.1809C98.3149 56.2132 98.5097 57.2926 98.7133 58.3166C98.9401 59.3724 99.0797 60.4434 99.2193 61.5144C99.3051 62.0382 99.3589 62.5854 99.3895 63.1008C99.454 63.6402 99.5184 64.1796 99.5829 64.7191C99.9438 69.0015 99.8456 73.3278 99.2653 77.6661L99.1325 78.4971L98.9765 79.2963C98.9199 79.8268 98.8082 80.349 98.7197 80.903L98.3526 82.493C98.2089 83.0387 98.0652 83.5843 97.8983 84.0981C97.3323 86.2254 96.5776 88.2957 95.6112 90.2771C94.6447 92.2586 93.4896 94.183 92.0853 95.8994C91.3831 96.7575 90.6346 97.5519 89.8078 98.3061C88.9898 99.0049 88.0616 99.6869 87.0904 100.107Z"
                fill="#27323D"
              />
              <path
                d="M87.0904 100.107C87.4554 99.7084 87.7944 99.2964 88.1072 98.871C88.42 98.4455 88.7145 98.0173 88.9906 97.5863C89.5486 96.6873 90.0514 95.78 90.4991 94.8643C91.4159 93.0172 92.144 91.1131 92.7706 89.1369C93.3883 87.216 93.9046 85.2231 94.3337 83.2453C94.7716 81.212 95.0904 79.2175 95.3629 77.1592C95.9309 73.0744 96.2783 68.9562 96.4923 64.7893C96.5182 63.7434 96.6312 62.6824 96.6571 61.6365L96.7396 60.06C96.7487 59.5318 96.7761 59.0063 96.822 58.4836C96.8479 57.4377 96.9058 56.3683 96.9637 55.2989C97.0032 54.2267 97.0718 53.1494 97.1695 52.0671C97.4941 53.1 97.7896 54.1379 98.0561 55.1809C98.3149 56.2132 98.5097 57.2926 98.7133 58.3166C98.9401 59.3724 99.0797 60.4434 99.2193 61.5144C99.3051 62.0382 99.3589 62.5854 99.3895 63.1008C99.454 63.6402 99.5184 64.1796 99.5829 64.7191C99.9438 69.0015 99.8456 73.3278 99.2653 77.6661L99.1325 78.4971L98.9765 79.2963C98.9199 79.8268 98.8082 80.349 98.7197 80.903L98.3526 82.493C98.2089 83.0387 98.0652 83.5843 97.8983 84.0981C97.3323 86.2254 96.5776 88.2957 95.6112 90.2771C94.6447 92.2586 93.4896 94.183 92.0853 95.8994C91.3831 96.7575 90.6346 97.5519 89.8078 98.3061C88.9898 99.0049 88.0616 99.6869 87.0904 100.107Z"
                fill="#27323D"
              />
              <path
                d="M80.1106 106.65C80.5124 106.257 80.8911 105.832 81.2466 105.376L81.7568 104.658C81.9025 104.454 82.0571 104.193 82.2029 103.988L82.6578 103.263L83.0896 102.506C83.3348 102.032 83.612 101.535 83.866 101.006L84.5642 99.4663L85.2071 97.9182C85.6512 96.9074 85.9706 95.7925 86.3915 94.7498L86.6578 93.9673C86.7341 93.6668 86.8015 93.4217 86.901 93.153L87.3873 91.5244L87.6304 90.7101C87.7085 90.4571 87.7789 90.1935 87.8416 89.9192L88.2495 88.2503C88.8475 86.0995 89.2889 83.8682 89.7854 81.6453L89.8794 81.2339L89.9183 80.8142L90.1062 79.9915L90.4039 78.3059L90.7015 76.6202L90.8575 75.821L90.9672 74.9581C91.1443 73.85 91.3214 72.7419 91.5073 71.5784C91.6844 70.4704 91.8935 69.3387 92.0154 68.2223C92.1771 67.093 92.3388 65.9636 92.5004 64.8343C92.6223 63.7178 92.8314 62.5862 92.9854 61.4462C93.2613 59.1898 93.5371 56.9334 93.9322 54.6383C94.1296 56.9382 94.1616 59.2129 94.2167 61.5196C94.2283 62.6846 94.1615 63.8094 94.1179 64.9662C94.1034 66.1178 94.0598 67.2745 93.9871 68.4363C93.9537 68.9987 93.9203 69.5611 93.8869 70.1235L93.7228 71.8578C93.624 73.0061 93.4701 74.1461 93.3393 75.3179C93.3134 75.5978 93.2875 75.8776 93.2616 76.1574L93.0968 77.012L92.8223 78.7295L92.5478 80.447L92.415 81.2781L92.321 81.6894L92.2502 82.1327L91.4573 85.5024C91.2162 86.6575 90.8736 87.7405 90.5542 88.8553L90.0591 90.5394C89.9751 90.8293 89.8833 91.1086 89.7839 91.3772L89.4856 92.1832L88.8569 93.8186C88.7575 94.0873 88.6812 94.3878 88.5586 94.6246L88.2051 95.4222L87.4662 97.0408C87.2673 97.5782 86.9581 98.0987 86.6809 98.5958L85.8404 100.142L84.8985 101.617C84.7439 101.877 84.6213 102.114 84.4116 102.366L83.8783 103.051L83.345 103.736C83.1566 103.972 82.9604 104.198 82.7566 104.413C81.9992 105.263 81.1084 106.064 80.1106 106.65Z"
                fill="#27323D"
              />
              <path
                d="M80.1106 106.65C80.5124 106.257 80.8911 105.832 81.2466 105.376L81.7568 104.658C81.9025 104.454 82.0571 104.193 82.2029 103.988L82.6578 103.263L83.0896 102.506C83.3348 102.032 83.612 101.535 83.866 101.006L84.5642 99.4663L85.2071 97.9182C85.6512 96.9074 85.9706 95.7925 86.3915 94.7498L86.6578 93.9673C86.7341 93.6668 86.8015 93.4217 86.901 93.153L87.3873 91.5244L87.6304 90.7101C87.7085 90.4571 87.7789 90.1935 87.8416 89.9192L88.2495 88.2503C88.8475 86.0995 89.2889 83.8682 89.7854 81.6453L89.8794 81.2339L89.9183 80.8142L90.1062 79.9915L90.4039 78.3059L90.7015 76.6202L90.8575 75.821L90.9672 74.9581C91.1443 73.85 91.3214 72.7419 91.5073 71.5784C91.6844 70.4704 91.8935 69.3387 92.0154 68.2223C92.1771 67.093 92.3388 65.9636 92.5004 64.8343C92.6223 63.7178 92.8314 62.5862 92.9854 61.4462C93.2613 59.1898 93.5371 56.9334 93.9322 54.6383C94.1296 56.9382 94.1616 59.2129 94.2167 61.5196C94.2283 62.6846 94.1615 63.8094 94.1179 64.9662C94.1034 66.1178 94.0598 67.2745 93.9871 68.4363C93.9537 68.9987 93.9203 69.5611 93.8869 70.1235L93.7228 71.8578C93.624 73.0061 93.4701 74.1461 93.3393 75.3179C93.3134 75.5978 93.2875 75.8776 93.2616 76.1574L93.0968 77.012L92.8223 78.7295L92.5478 80.447L92.415 81.2781L92.321 81.6894L92.2502 82.1327L91.4573 85.5024C91.2162 86.6575 90.8736 87.7405 90.5542 88.8553L90.0591 90.5394C89.9751 90.8293 89.8833 91.1086 89.7839 91.3772L89.4856 92.1832L88.8569 93.8186C88.7575 94.0873 88.6812 94.3878 88.5586 94.6246L88.2051 95.4222L87.4662 97.0408C87.2673 97.5782 86.9581 98.0987 86.6809 98.5958L85.8404 100.142L84.8985 101.617C84.7439 101.877 84.6213 102.114 84.4116 102.366L83.8783 103.051L83.345 103.736C83.1566 103.972 82.9604 104.198 82.7566 104.413C81.9992 105.263 81.1084 106.064 80.1106 106.65Z"
                fill="#27323D"
              />
              <path
                d="M73.1388 107.861C74.5235 103.616 76.1431 99.4922 77.809 95.4321C79.4518 91.3402 81.1729 87.2885 82.83 83.2838C84.5191 79.2556 86.185 75.1955 87.6214 71.1574C88.3672 69.1425 89.0347 67.0873 89.5366 65.0071C89.7921 63.9392 89.9835 62.9184 90.0965 61.8574C90.1544 60.788 90.1252 59.7337 89.7329 58.6527C90.1211 59.0521 90.4044 59.5774 90.6557 60.1263C90.8732 60.6511 91.0403 61.1966 91.1569 61.7629C91.398 62.906 91.4647 64.0795 91.4531 65.2127C91.3979 67.5026 91.0669 69.7506 90.6024 71.95C89.6413 76.3724 88.1695 80.6322 86.4395 84.7393C84.7327 88.8783 82.7678 92.8647 80.568 96.7303C79.468 98.6631 78.3129 100.587 77.0884 102.416C75.8549 104.3 74.5752 106.121 73.1388 107.861Z"
                fill="#27323D"
              />
              <path
                d="M73.1388 107.861C74.5235 103.616 76.1431 99.4922 77.809 95.4321C79.4518 91.3402 81.1729 87.2885 82.83 83.2838C84.5191 79.2556 86.185 75.1955 87.6214 71.1574C88.3672 69.1425 89.0347 67.0873 89.5366 65.0071C89.7921 63.9392 89.9835 62.9184 90.0965 61.8574C90.1544 60.788 90.1252 59.7337 89.7329 58.6527C90.1211 59.0521 90.4044 59.5774 90.6557 60.1263C90.8732 60.6511 91.0403 61.1966 91.1569 61.7629C91.398 62.906 91.4647 64.0795 91.4531 65.2127C91.3979 67.5026 91.0669 69.7506 90.6024 71.95C89.6413 76.3724 88.1695 80.6322 86.4395 84.7393C84.7327 88.8783 82.7678 92.8647 80.568 96.7303C79.468 98.6631 78.3129 100.587 77.0884 102.416C75.8549 104.3 74.5752 106.121 73.1388 107.861Z"
                fill="#27323D"
              />
              <path
                d="M66.3254 108.417L72.5123 96.9275C73.5512 95.0233 74.5639 93.1056 75.5506 91.1745C76.5633 89.2569 77.5209 87.3309 78.4554 85.373C80.3154 81.5126 82.0739 77.5801 83.5104 73.5419C84.233 71.4951 84.8685 69.4635 85.3153 67.3749C85.8172 65.2946 86.1306 63.1574 86.1129 60.9699C86.6768 63.0984 86.6856 65.3412 86.5609 67.5355C86.3722 69.7767 85.9397 71.9527 85.3969 74.1118C84.288 78.3983 82.6453 82.4903 80.7124 86.4531C80.2315 87.4584 79.7352 88.4424 79.2235 89.4051C78.7011 90.3757 78.1788 91.3463 77.6564 92.3168C76.5807 94.2155 75.4345 96.0845 74.2176 97.9239C73.0221 99.7476 71.7744 101.545 70.4745 103.315C69.1253 105.039 67.7992 106.796 66.3254 108.417Z"
                fill="#27323D"
              />
              <path
                d="M66.3254 108.417L72.5123 96.9275C73.5512 95.0233 74.5639 93.1056 75.5506 91.1745C76.5633 89.2569 77.5209 87.3309 78.4554 85.373C80.3154 81.5126 82.0739 77.5801 83.5104 73.5419C84.233 71.4951 84.8685 69.4635 85.3153 67.3749C85.8172 65.2946 86.1306 63.1574 86.1129 60.9699C86.6768 63.0984 86.6856 65.3412 86.5609 67.5355C86.3722 69.7767 85.9397 71.9527 85.3969 74.1118C84.288 78.3983 82.6453 82.4903 80.7124 86.4531C80.2315 87.4584 79.7352 88.4424 79.2235 89.4051C78.7011 90.3757 78.1788 91.3463 77.6564 92.3168C76.5807 94.2155 75.4345 96.0845 74.2176 97.9239C73.0221 99.7476 71.7744 101.545 70.4745 103.315C69.1253 105.039 67.7992 106.796 66.3254 108.417Z"
                fill="#27323D"
              />
              <path
                d="M59.5526 110.313C60.472 109.687 61.2526 108.869 62.0331 108.051C62.2156 107.851 62.3981 107.652 62.5806 107.453L62.8864 107.13C62.9623 107.009 63.0458 106.899 63.1371 106.799L64.1628 105.508L65.1421 104.153C65.3199 103.924 65.4976 103.696 65.6434 103.491L66.0984 102.766L67.0226 101.402C67.3229 100.937 67.6001 100.44 67.8773 99.9428L68.7232 98.5389C69.0004 98.0419 69.2456 97.5683 69.4996 97.0394L70.2672 95.5953C70.4218 95.335 70.5444 95.0982 70.667 94.8615L71.0436 94.0958L72.4542 91.1035L73.7632 88.0391C73.9621 87.5018 74.2393 87.0048 74.4381 86.4675L75.0259 84.9109L76.2566 81.8063L78.4831 75.4762C78.8227 74.4117 79.1731 73.3394 79.534 72.2592L80.0667 70.6943C80.2655 70.157 80.4644 69.6197 80.6081 69.074C81.2613 66.9316 81.9465 64.7656 82.71 62.6399C82.4893 64.9047 82.1351 67.1209 81.804 69.3689C81.7155 69.923 81.5949 70.5005 81.4832 71.0227L81.1304 72.7C80.8952 73.8181 80.6523 74.9257 80.4016 76.0226C80.1374 77.1459 79.7948 78.2288 79.4522 79.3118C79.1648 80.4032 78.8483 81.4996 78.5027 82.601L77.3415 85.8013L76.7449 87.4132C76.546 87.9505 76.3239 88.456 76.0699 88.9849L74.72 92.1282C74.181 93.1436 73.6605 94.1617 73.1583 95.1826L72.7265 95.94C72.6039 96.1767 72.4493 96.437 72.2947 96.6973L71.4079 98.1801L70.953 98.9055C70.8304 99.1423 70.6758 99.4026 70.498 99.631L69.5098 101.042L69.0317 101.735C68.8539 101.964 68.6993 102.224 68.4984 102.42L67.4087 103.759L66.8754 104.444C66.6715 104.659 66.4783 104.866 66.2958 105.065L65.1046 106.332L63.8208 107.471C63.7082 107.586 63.5986 107.683 63.4919 107.761L63.1629 108.052C62.9282 108.224 62.7041 108.389 62.4907 108.546C62.3781 108.661 62.2685 108.758 62.1618 108.836L61.7865 109.063L61.0591 109.549L60.3406 109.979C60.0934 110.111 59.823 110.212 59.5526 110.313Z"
                fill="#27323D"
              />
              <path
                d="M59.5526 110.313C60.472 109.687 61.2526 108.869 62.0331 108.051C62.2156 107.851 62.3981 107.652 62.5806 107.453L62.8864 107.13C62.9623 107.009 63.0458 106.899 63.1371 106.799L64.1628 105.508L65.1421 104.153C65.3199 103.924 65.4976 103.696 65.6434 103.491L66.0984 102.766L67.0226 101.402C67.3229 100.937 67.6001 100.44 67.8773 99.9428L68.7232 98.5389C69.0004 98.0419 69.2456 97.5683 69.4996 97.0394L70.2672 95.5953C70.4218 95.335 70.5444 95.0982 70.667 94.8615L71.0436 94.0958L72.4542 91.1035L73.7632 88.0391C73.9621 87.5018 74.2393 87.0048 74.4381 86.4675L75.0259 84.9109L76.2566 81.8063L78.4831 75.4762C78.8227 74.4117 79.1731 73.3394 79.534 72.2592L80.0667 70.6943C80.2655 70.157 80.4644 69.6197 80.6081 69.074C81.2613 66.9316 81.9465 64.7656 82.71 62.6399C82.4893 64.9047 82.1351 67.1209 81.804 69.3689C81.7155 69.923 81.5949 70.5005 81.4832 71.0227L81.1304 72.7C80.8952 73.8181 80.6523 74.9257 80.4016 76.0226C80.1374 77.1459 79.7948 78.2288 79.4522 79.3118C79.1648 80.4032 78.8483 81.4996 78.5027 82.601L77.3415 85.8013L76.7449 87.4132C76.546 87.9505 76.3239 88.456 76.0699 88.9849L74.72 92.1282C74.181 93.1436 73.6605 94.1617 73.1583 95.1826L72.7265 95.94C72.6039 96.1767 72.4493 96.437 72.2947 96.6973L71.4079 98.1801L70.953 98.9055C70.8304 99.1423 70.6758 99.4026 70.498 99.631L69.5098 101.042L69.0317 101.735C68.8539 101.964 68.6993 102.224 68.4984 102.42L67.4087 103.759L66.8754 104.444C66.6715 104.659 66.4783 104.866 66.2958 105.065L65.1046 106.332L63.8208 107.471C63.7082 107.586 63.5986 107.683 63.4919 107.761L63.1629 108.052C62.9282 108.224 62.7041 108.389 62.4907 108.546C62.3781 108.661 62.2685 108.758 62.1618 108.836L61.7865 109.063L61.0591 109.549L60.3406 109.979C60.0934 110.111 59.823 110.212 59.5526 110.313Z"
                fill="#27323D"
              />
              <path
                d="M56.4566 107.943C56.868 107.608 57.2609 107.27 57.6355 106.93C58.0315 106.574 58.4119 106.196 58.777 105.798C59.5166 105.059 60.2563 104.32 60.9176 103.541C62.3042 101.935 63.5806 100.313 64.8106 98.6266C67.2386 95.278 69.339 91.6811 71.1725 87.9869L71.876 86.5898L72.5244 85.1844C72.7696 84.7108 72.9596 84.2289 73.1728 83.7789L73.798 82.3416C74.1781 81.3778 74.6133 80.4223 74.9702 79.4267L75.5402 77.9809L76.0551 76.5269C77.4739 72.5995 78.6808 68.5833 79.8965 64.5117C79.7289 68.7423 79.0008 72.9447 77.9417 77.0968L77.5282 78.623L77.0596 80.1409C76.7491 81.2003 76.369 82.1641 76.0033 83.2152C75.2343 85.1982 74.4102 87.1729 73.4526 89.0989C71.5374 92.9509 69.1755 96.5933 66.3381 99.8514C64.9195 101.481 63.4314 103.014 61.7723 104.38C60.9543 105.078 60.1132 105.745 59.2258 106.348C58.3384 106.951 57.4278 107.523 56.4566 107.943Z"
                fill="#27323D"
              />
              <path
                d="M56.4566 107.943C56.868 107.608 57.2609 107.27 57.6355 106.93C58.0315 106.574 58.4119 106.196 58.777 105.798C59.5166 105.059 60.2563 104.32 60.9176 103.541C62.3042 101.935 63.5806 100.313 64.8106 98.6266C67.2386 95.278 69.339 91.6811 71.1725 87.9869L71.876 86.5898L72.5244 85.1844C72.7696 84.7108 72.9596 84.2289 73.1728 83.7789L73.798 82.3416C74.1781 81.3778 74.6133 80.4223 74.9702 79.4267L75.5402 77.9809L76.0551 76.5269C77.4739 72.5995 78.6808 68.5833 79.8965 64.5117C79.7289 68.7423 79.0008 72.9447 77.9417 77.0968L77.5282 78.623L77.0596 80.1409C76.7491 81.2003 76.369 82.1641 76.0033 83.2152C75.2343 85.1982 74.4102 87.1729 73.4526 89.0989C71.5374 92.9509 69.1755 96.5933 66.3381 99.8514C64.9195 101.481 63.4314 103.014 61.7723 104.38C60.9543 105.078 60.1132 105.745 59.2258 106.348C58.3384 106.951 57.4278 107.523 56.4566 107.943Z"
                fill="#27323D"
              />
              <path
                d="M53.0068 110.625C55.4988 107.229 57.8253 103.808 60.0504 100.316C62.3016 96.836 64.4038 93.2867 66.3571 89.6674C66.8155 88.7439 67.297 87.8522 67.7554 86.9287C68.2369 86.037 68.6721 85.0816 69.1305 84.158C70.0472 82.3109 70.9088 80.4555 71.724 78.5362C73.3457 74.7532 74.7555 70.8812 76.1511 66.922C75.989 68.9971 75.6525 71.1024 75.2145 73.1356C74.9911 74.18 74.7446 75.1924 74.466 76.2283C74.3311 76.7186 74.1643 77.2324 74.0294 77.7226C73.8625 78.2364 73.7277 78.7267 73.5608 79.2405L73.0691 80.7264C72.879 81.2083 72.6802 81.7456 72.4902 82.2275C72.1333 83.2232 71.698 84.1787 71.318 85.1425C70.8828 86.0979 70.4707 87.0852 69.9804 88.0323C69.5452 88.9877 69.0316 89.9029 68.5412 90.85C66.5334 94.5744 64.2444 98.1144 61.674 101.47C60.3657 103.116 59.0342 104.73 57.6012 106.271C56.1451 107.781 54.657 109.315 53.0068 110.625Z"
                fill="#27323D"
              />
              <path
                d="M53.0068 110.625C55.4988 107.229 57.8253 103.808 60.0504 100.316C62.3016 96.836 64.4038 93.2867 66.3571 89.6674C66.8155 88.7439 67.297 87.8522 67.7554 86.9287C68.2369 86.037 68.6721 85.0816 69.1305 84.158C70.0472 82.3109 70.9088 80.4555 71.724 78.5362C73.3457 74.7532 74.7555 70.8812 76.1511 66.922C75.989 68.9971 75.6525 71.1024 75.2145 73.1356C74.9911 74.18 74.7446 75.1924 74.466 76.2283C74.3311 76.7186 74.1643 77.2324 74.0294 77.7226C73.8625 78.2364 73.7277 78.7267 73.5608 79.2405L73.0691 80.7264C72.879 81.2083 72.6802 81.7456 72.4902 82.2275C72.1333 83.2232 71.698 84.1787 71.318 85.1425C70.8828 86.0979 70.4707 87.0852 69.9804 88.0323C69.5452 88.9877 69.0316 89.9029 68.5412 90.85C66.5334 94.5744 64.2444 98.1144 61.674 101.47C60.3657 103.116 59.0342 104.73 57.6012 106.271C56.1451 107.781 54.657 109.315 53.0068 110.625Z"
                fill="#27323D"
              />
              <path
                d="M42.581 112.224C44.3118 110.822 45.9702 109.343 47.5562 107.786C49.1731 106.273 50.7222 104.711 52.2038 103.1C53.7096 101.456 55.1603 99.8036 56.5095 98.0789C57.1797 97.2442 57.8819 96.3861 58.5057 95.4876L59.007 94.8259L59.4762 94.1878C59.8318 93.731 60.1553 93.2977 60.4556 92.8326L61.3478 91.4925C61.6482 91.0273 61.9485 90.5621 62.2489 90.097C62.5463 89.6503 62.836 89.193 63.1179 88.725C63.3951 88.228 63.6955 87.7628 63.9638 87.3212C65.0869 85.4202 66.0765 83.4707 67.0661 81.5212C68.9759 77.5265 70.5635 73.4261 72.0033 69.1899C71.6028 73.6405 70.4994 78.0697 68.862 82.3044C68.4411 83.3471 68.0202 84.3898 67.5441 85.4242C67.299 85.8977 67.1001 86.435 66.8229 86.932L66.0465 88.4316C64.9608 90.4516 63.7737 92.3996 62.5171 94.2519C62.1848 94.7406 61.8293 95.1973 61.5057 95.6306L61.0276 96.3242L60.4944 97.0094C59.7892 97.886 59.0657 98.7599 58.3238 99.631C56.8459 101.336 55.2694 102.951 53.5945 104.475C51.9088 106.007 50.1858 107.419 48.3612 108.76C46.4903 110.036 44.6283 111.258 42.581 112.224Z"
                fill="#27323D"
              />
              <path
                d="M42.581 112.224C44.3118 110.822 45.9702 109.343 47.5562 107.786C49.1731 106.273 50.7222 104.711 52.2038 103.1C53.7096 101.456 55.1603 99.8036 56.5095 98.0789C57.1797 97.2442 57.8819 96.3861 58.5057 95.4876L59.007 94.8259L59.4762 94.1878C59.8318 93.731 60.1553 93.2977 60.4556 92.8326L61.3478 91.4925C61.6482 91.0273 61.9485 90.5621 62.2489 90.097C62.5463 89.6503 62.836 89.193 63.1179 88.725C63.3951 88.228 63.6955 87.7628 63.9638 87.3212C65.0869 85.4202 66.0765 83.4707 67.0661 81.5212C68.9759 77.5265 70.5635 73.4261 72.0033 69.1899C71.6028 73.6405 70.4994 78.0697 68.862 82.3044C68.4411 83.3471 68.0202 84.3898 67.5441 85.4242C67.299 85.8977 67.1001 86.435 66.8229 86.932L66.0465 88.4316C64.9608 90.4516 63.7737 92.3996 62.5171 94.2519C62.1848 94.7406 61.8293 95.1973 61.5057 95.6306L61.0276 96.3242L60.4944 97.0094C59.7892 97.886 59.0657 98.7599 58.3238 99.631C56.8459 101.336 55.2694 102.951 53.5945 104.475C51.9088 106.007 50.1858 107.419 48.3612 108.76C46.4903 110.036 44.6283 111.258 42.581 112.224Z"
                fill="#27323D"
              />
              <path
                d="M42.3802 106.402C43.2179 105.933 43.9862 105.368 44.7312 104.772C45.4763 104.176 46.1751 103.516 46.8739 102.856C47.5175 102.187 48.2162 101.527 48.8367 100.827L49.8181 99.8122L50.7301 98.7021L51.6652 97.6239L52.5771 96.5138C53.1513 95.7497 53.7717 95.0493 54.3227 94.2533C54.8648 93.5128 55.439 92.7486 56.0131 91.9845L57.6341 89.62C58.2082 88.8559 58.704 88.0516 59.255 87.2556L60.8208 84.8827C61.8444 83.2505 62.8913 81.6501 63.9149 80.0178C64.403 79.2028 64.9095 78.3906 65.4344 77.5812L66.1985 76.3352C66.4687 75.9411 66.7205 75.5442 66.9538 75.1446C67.9543 73.4804 68.978 71.8482 70.0568 70.2243C69.4799 72.0662 68.7696 73.8595 68.0503 75.7082C67.7112 76.593 67.2616 77.4612 66.8673 78.3377C66.4623 79.222 66.0573 80.1064 65.6523 80.9907C65.2259 81.8908 64.7301 82.6951 64.2806 83.5633C63.8311 84.4314 63.3264 85.2912 62.8537 86.1274C62.3811 86.9637 61.8532 87.7916 61.3254 88.6195C60.7976 89.4474 60.2697 90.2752 59.7187 91.0712L58.0106 93.4509C57.4045 94.2385 56.7752 94.9943 56.1779 95.7265C55.5717 96.5141 54.8961 97.2061 54.2116 97.9535L53.2213 99.0233L52.1528 100.053C51.8149 100.399 51.4588 100.742 51.0842 101.082L49.9693 102.048C49.2473 102.676 48.4239 103.233 47.6237 103.82C47.2396 104.103 46.7771 104.345 46.393 104.627C45.9857 104.877 45.5233 105.119 45.0929 105.338C44.6856 105.588 44.232 105.774 43.7784 105.961C43.3248 106.148 42.8481 106.302 42.3802 106.402Z"
                fill="#27323D"
              />
              <path
                d="M42.3802 106.402C43.2179 105.933 43.9862 105.368 44.7312 104.772C45.4763 104.176 46.1751 103.516 46.8739 102.856C47.5175 102.187 48.2162 101.527 48.8367 100.827L49.8181 99.8122L50.7301 98.7021L51.6652 97.6239L52.5771 96.5138C53.1513 95.7497 53.7717 95.0493 54.3227 94.2533C54.8648 93.5128 55.439 92.7486 56.0131 91.9845L57.6341 89.62C58.2082 88.8559 58.704 88.0516 59.255 87.2556L60.8208 84.8827C61.8444 83.2505 62.8913 81.6501 63.9149 80.0178C64.403 79.2028 64.9095 78.3906 65.4344 77.5812L66.1985 76.3352C66.4687 75.9411 66.7205 75.5442 66.9538 75.1446C67.9543 73.4804 68.978 71.8482 70.0568 70.2243C69.4799 72.0662 68.7696 73.8595 68.0503 75.7082C67.7112 76.593 67.2616 77.4612 66.8673 78.3377C66.4623 79.222 66.0573 80.1064 65.6523 80.9907C65.2259 81.8908 64.7301 82.6951 64.2806 83.5633C63.8311 84.4314 63.3264 85.2912 62.8537 86.1274C62.3811 86.9637 61.8532 87.7916 61.3254 88.6195C60.7976 89.4474 60.2697 90.2752 59.7187 91.0712L58.0106 93.4509C57.4045 94.2385 56.7752 94.9943 56.1779 95.7265C55.5717 96.5141 54.8961 97.2061 54.2116 97.9535L53.2213 99.0233L52.1528 100.053C51.8149 100.399 51.4588 100.742 51.0842 101.082L49.9693 102.048C49.2473 102.676 48.4239 103.233 47.6237 103.82C47.2396 104.103 46.7771 104.345 46.393 104.627C45.9857 104.877 45.5233 105.119 45.0929 105.338C44.6856 105.588 44.232 105.774 43.7784 105.961C43.3248 106.148 42.8481 106.302 42.3802 106.402Z"
                fill="#27323D"
              />
              <path
                d="M35.0365 110.279C36.998 106.491 39.3422 102.96 41.756 99.5237C44.2124 96.0564 46.8011 92.7038 49.5222 89.4658C50.9088 87.8603 52.2955 86.2547 53.7285 84.7129C54.4449 83.942 55.1383 83.1393 55.8779 82.4002C56.6234 81.6243 57.3583 80.8562 58.0825 80.0959C59.5618 78.6179 61.0962 77.1483 62.6538 75.7105C64.2114 74.2727 65.8153 72.8988 67.4655 71.5885C66.2035 73.2981 64.872 74.9121 63.5637 76.5579C62.2554 78.2037 60.8687 79.8093 59.5604 81.4551L55.5107 86.2885L51.438 91.09L43.3156 100.725C40.5743 103.913 37.833 107.1 35.0365 110.279Z"
                fill="#27323D"
              />
              <path
                d="M35.0365 110.279C36.998 106.491 39.3422 102.96 41.756 99.5237C44.2124 96.0564 46.8011 92.7038 49.5222 89.4658C50.9088 87.8603 52.2955 86.2547 53.7285 84.7129C54.4449 83.942 55.1383 83.1393 55.8779 82.4002C56.6234 81.6243 57.3583 80.8562 58.0825 80.0959C59.5618 78.6179 61.0962 77.1483 62.6538 75.7105C64.2114 74.2727 65.8153 72.8988 67.4655 71.5885C66.2035 73.2981 64.872 74.9121 63.5637 76.5579C62.2554 78.2037 60.8687 79.8093 59.5604 81.4551L55.5107 86.2885L51.438 91.09L43.3156 100.725C40.5743 103.913 37.833 107.1 35.0365 110.279Z"
                fill="#27323D"
              />
              <path
                d="M26.0455 97.7695C29.3718 96.4015 32.6286 94.9378 35.7928 93.3465C38.957 91.7552 42.0518 90.0683 45.0076 88.1901C47.9634 86.3118 50.8586 84.2825 53.5597 82.0535C56.3159 79.8329 58.8318 77.3488 61.3653 74.7539C60.9335 75.5112 60.516 76.3559 60.0291 77.1048C59.7696 77.4911 59.5101 77.8773 59.2826 78.24L58.481 79.3669L58.0526 79.9261L57.6331 80.4299L56.7531 81.5165L55.8589 82.5158C55.5424 82.8461 55.2259 83.1764 54.9095 83.5067C52.3208 86.0933 49.4133 88.3761 46.3295 90.3484C43.2545 92.2653 39.9711 93.8952 36.5753 95.1676C34.8736 95.7985 33.1469 96.3499 31.3952 96.8219C29.634 97.2357 27.8496 97.6176 26.0455 97.7695Z"
                fill="#27323D"
              />
              <path
                d="M26.0455 97.7695C29.3718 96.4015 32.6286 94.9378 35.7928 93.3465C38.957 91.7552 42.0518 90.0683 45.0076 88.1901C47.9634 86.3118 50.8586 84.2825 53.5597 82.0535C56.3159 79.8329 58.8318 77.3488 61.3653 74.7539C60.9335 75.5112 60.516 76.3559 60.0291 77.1048C59.7696 77.4911 59.5101 77.8773 59.2826 78.24L58.481 79.3669L58.0526 79.9261L57.6331 80.4299L56.7531 81.5165L55.8589 82.5158C55.5424 82.8461 55.2259 83.1764 54.9095 83.5067C52.3208 86.0933 49.4133 88.3761 46.3295 90.3484C43.2545 92.2653 39.9711 93.8952 36.5753 95.1676C34.8736 95.7985 33.1469 96.3499 31.3952 96.8219C29.634 97.2357 27.8496 97.6176 26.0455 97.7695Z"
                fill="#27323D"
              />
              <path
                d="M19.8075 98.4366C20.6384 98.3641 21.487 98.1807 22.3036 98.0208C23.1231 97.8425 23.9301 97.6245 24.7247 97.3667C26.3293 96.8724 27.9196 96.2908 29.4404 95.6135C30.9843 94.9682 32.4908 94.2036 33.9742 93.4072C34.3406 93.2358 34.7022 93.0353 35.0591 92.8057C35.4023 92.6024 35.8008 92.4075 36.144 92.2042L37.2378 91.5473L38.2764 90.882C38.6333 90.6524 38.9902 90.4228 39.347 90.1932C39.6671 89.958 40.0424 89.7312 40.3625 89.4961C41.0899 89.0105 41.7852 88.5486 42.4343 88.0228L43.4266 87.2938L44.4509 86.5412C45.1231 86.0473 45.749 85.4897 46.3981 84.9639C47.0703 84.47 47.6962 83.9124 48.3452 83.3866L50.2229 81.7137L52.0775 80.0089L53.9089 78.2722L55.3676 80.281C55.2164 80.3432 55.1524 80.3902 55.0332 80.4289L54.7628 80.5297C54.5613 80.6127 54.3703 80.6877 54.1901 80.755C53.8296 80.8894 53.4691 81.0239 53.1086 81.1584C52.3721 81.406 51.6339 81.6062 50.8938 81.7587C49.4172 82.1589 47.8801 82.4081 46.3838 82.5783C44.8875 82.7486 43.377 82.8315 41.8521 82.8272C40.3456 82.8257 38.8402 82.7581 37.336 82.6245C34.3306 82.3388 31.4014 81.7525 28.5396 80.9212C25.6778 80.0898 22.9066 79.0452 20.258 77.7638C23.0967 78.5633 25.9441 79.3073 28.8039 79.7979C30.2477 80.0737 31.6926 80.2834 33.1387 80.4272C33.8648 80.4806 34.5819 80.5893 35.3079 80.6427C36.0339 80.6961 36.7367 80.7176 37.4948 80.7475C40.3613 80.8419 43.2633 80.7146 46.0992 80.2936C47.5094 80.0725 48.9131 79.7747 50.3101 79.4003C50.998 79.2209 51.6704 79.0202 52.3274 78.7983C52.6666 78.6795 53.0058 78.5608 53.3449 78.442C53.4884 78.3692 53.6396 78.307 53.7985 78.2554L54.0457 78.1227L54.229 78.037L60.7603 74.9987L55.6557 80.0693C53.2127 82.451 50.6363 84.784 47.9297 86.8703C45.1912 88.9801 42.36 90.9624 39.3204 92.6577C36.336 94.3614 33.1752 95.7545 29.8932 96.8456C28.246 97.3712 26.5797 97.7805 24.8943 98.0734C23.2059 98.3847 21.5122 98.5534 19.8075 98.4366Z"
                fill="#27323D"
              />
              <path
                d="M19.8075 98.4366C20.6384 98.3641 21.487 98.1807 22.3036 98.0208C23.1231 97.8425 23.9301 97.6245 24.7247 97.3667C26.3293 96.8724 27.9196 96.2908 29.4404 95.6135C30.9843 94.9682 32.4908 94.2036 33.9742 93.4072C34.3406 93.2358 34.7022 93.0353 35.0591 92.8057C35.4023 92.6024 35.8008 92.4075 36.144 92.2042L37.2378 91.5473L38.2764 90.882C38.6333 90.6524 38.9902 90.4228 39.347 90.1932C39.6671 89.958 40.0424 89.7312 40.3625 89.4961C41.0899 89.0105 41.7852 88.5486 42.4343 88.0228L43.4266 87.2938L44.4509 86.5412C45.1231 86.0473 45.749 85.4897 46.3981 84.9639C47.0703 84.47 47.6962 83.9124 48.3452 83.3866L50.2229 81.7137L52.0775 80.0089L53.9089 78.2722L55.3676 80.281C55.2164 80.3432 55.1524 80.3902 55.0332 80.4289L54.7628 80.5297C54.5613 80.6127 54.3703 80.6877 54.1901 80.755C53.8296 80.8894 53.4691 81.0239 53.1086 81.1584C52.3721 81.406 51.6339 81.6062 50.8938 81.7587C49.4172 82.1589 47.8801 82.4081 46.3838 82.5783C44.8875 82.7486 43.377 82.8315 41.8521 82.8272C40.3456 82.8257 38.8402 82.7581 37.336 82.6245C34.3306 82.3388 31.4014 81.7525 28.5396 80.9212C25.6778 80.0898 22.9066 79.0452 20.258 77.7638C23.0967 78.5633 25.9441 79.3073 28.8039 79.7979C30.2477 80.0737 31.6926 80.2834 33.1387 80.4272C33.8648 80.4806 34.5819 80.5893 35.3079 80.6427C36.0339 80.6961 36.7367 80.7176 37.4948 80.7475C40.3613 80.8419 43.2633 80.7146 46.0992 80.2936C47.5094 80.0725 48.9131 79.7747 50.3101 79.4003C50.998 79.2209 51.6704 79.0202 52.3274 78.7983C52.6666 78.6795 53.0058 78.5608 53.3449 78.442C53.4884 78.3692 53.6396 78.307 53.7985 78.2554L54.0457 78.1227L54.229 78.037L60.7603 74.9987L55.6557 80.0693C53.2127 82.451 50.6363 84.784 47.9297 86.8703C45.1912 88.9801 42.36 90.9624 39.3204 92.6577C36.336 94.3614 33.1752 95.7545 29.8932 96.8456C28.246 97.3712 26.5797 97.7805 24.8943 98.0734C23.2059 98.3847 21.5122 98.5534 19.8075 98.4366Z"
                fill="#27323D"
              />
              <path
                d="M21.2706 92.8164C23.9192 91.7995 26.5501 90.8935 29.1579 89.9555C31.7657 89.0175 34.3734 88.0796 36.9117 87.046C39.45 86.0123 41.9972 84.9233 44.4286 83.6749C45.6558 83.0666 46.86 82.4264 48.0729 81.7308C49.2627 81.0034 50.4294 80.244 51.6049 79.4293C50.8176 80.6434 49.8273 81.7132 48.8227 82.6958C47.7862 83.7019 46.6018 84.572 45.4263 85.3868C43.0753 87.0163 40.4927 88.3269 37.8352 89.3992C35.2097 90.448 32.4542 91.25 29.702 91.854C28.3167 92.1545 26.9142 92.3863 25.4945 92.5492C24.1085 92.7362 22.6763 92.8594 21.2706 92.8164Z"
                fill="#27323D"
              />
              <path
                d="M21.2706 92.8164C23.9192 91.7995 26.5501 90.8935 29.1579 89.9555C31.7657 89.0175 34.3734 88.0796 36.9117 87.046C39.45 86.0123 41.9972 84.9233 44.4286 83.6749C45.6558 83.0666 46.86 82.4264 48.0729 81.7308C49.2627 81.0034 50.4294 80.244 51.6049 79.4293C50.8176 80.6434 49.8273 81.7132 48.8227 82.6958C47.7862 83.7019 46.6018 84.572 45.4263 85.3868C43.0753 87.0163 40.4927 88.3269 37.8352 89.3992C35.2097 90.448 32.4542 91.25 29.702 91.854C28.3167 92.1545 26.9142 92.3863 25.4945 92.5492C24.1085 92.7362 22.6763 92.8594 21.2706 92.8164Z"
                fill="#27323D"
              />
              <path
                d="M51.2082 79.9648C50.2445 80.8685 49.1882 81.6445 48.0624 82.325C46.9597 83.0373 45.7645 83.6221 44.5461 84.1749C42.1413 85.2572 39.5921 86.0054 36.9768 86.4599C36.3114 86.5576 35.678 86.6317 35.0127 86.7294C34.3561 86.7716 33.6996 86.8139 33.043 86.8562C32.3634 86.8666 31.7068 86.9088 31.036 86.8638C30.7281 86.8455 30.3883 86.8507 30.0573 86.8005L29.0786 86.7371C27.7458 86.5917 26.4539 86.3673 25.1796 86.0321C23.9054 85.697 22.704 85.2594 21.5204 84.7109C24.1342 84.7954 26.6378 84.863 29.1679 84.7645C30.4015 84.7058 31.641 84.6101 32.8864 84.4774C33.5198 84.4033 34.1212 84.3526 34.7314 84.2466C35.3648 84.1724 35.975 84.0664 36.5853 83.9603C39.0416 83.5574 41.4741 83.009 43.8828 82.3151C46.3359 81.6375 48.7516 80.8406 51.2082 79.9648Z"
                fill="#27323D"
              />
              <path
                d="M51.2082 79.9648C50.2445 80.8685 49.1882 81.6445 48.0624 82.325C46.9597 83.0373 45.7645 83.6221 44.5461 84.1749C42.1413 85.2572 39.5921 86.0054 36.9768 86.4599C36.3114 86.5576 35.678 86.6317 35.0127 86.7294C34.3561 86.7716 33.6996 86.8139 33.043 86.8562C32.3634 86.8666 31.7068 86.9088 31.036 86.8638C30.7281 86.8455 30.3883 86.8507 30.0573 86.8005L29.0786 86.7371C27.7458 86.5917 26.4539 86.3673 25.1796 86.0321C23.9054 85.697 22.704 85.2594 21.5204 84.7109C24.1342 84.7954 26.6378 84.863 29.1679 84.7645C30.4015 84.7058 31.641 84.6101 32.8864 84.4774C33.5198 84.4033 34.1212 84.3526 34.7314 84.2466C35.3648 84.1724 35.975 84.0664 36.5853 83.9603C39.0416 83.5574 41.4741 83.009 43.8828 82.3151C46.3359 81.6375 48.7516 80.8406 51.2082 79.9648Z"
                fill="#27323D"
              />
              <path
                d="M56.8217 77.5501C55.5481 78.0947 54.2139 78.4881 52.8565 78.8497C51.5235 79.1771 50.1732 79.4357 48.8056 79.6255C46.0569 80.0313 43.2652 80.1753 40.4947 80.0103C39.8239 79.9653 39.1299 79.8884 38.4039 79.835C37.7419 79.7346 37.0479 79.6577 36.3628 79.5254C34.9837 79.3162 33.6454 79.028 32.2928 78.6526C29.6517 77.8547 27.0637 76.7244 24.7761 75.1289C27.4758 75.7371 30.0972 76.305 32.7451 76.7067C33.4071 76.8071 34.0459 76.8756 34.7079 76.9761C35.3787 77.0211 36.0407 77.1215 36.6795 77.19C38.0153 77.317 39.3463 77.4149 40.6725 77.4837C43.3327 77.6319 45.9786 77.6928 48.6654 77.6748C51.3521 77.6567 54.0477 77.5833 56.8217 77.5501Z"
                fill="#27323D"
              />
              <path
                d="M56.8217 77.5501C55.5481 78.0947 54.2139 78.4881 52.8565 78.8497C51.5235 79.1771 50.1732 79.4357 48.8056 79.6255C46.0569 80.0313 43.2652 80.1753 40.4947 80.0103C39.8239 79.9653 39.1299 79.8884 38.4039 79.835C37.7419 79.7346 37.0479 79.6577 36.3628 79.5254C34.9837 79.3162 33.6454 79.028 32.2928 78.6526C29.6517 77.8547 27.0637 76.7244 24.7761 75.1289C27.4758 75.7371 30.0972 76.305 32.7451 76.7067C33.4071 76.8071 34.0459 76.8756 34.7079 76.9761C35.3787 77.0211 36.0407 77.1215 36.6795 77.19C38.0153 77.317 39.3463 77.4149 40.6725 77.4837C43.3327 77.6319 45.9786 77.6928 48.6654 77.6748C51.3521 77.6567 54.0477 77.5833 56.8217 77.5501Z"
                fill="#27323D"
              />
              <path
                d="M63.7471 73.6387C63.3521 73.6355 63.0034 73.6961 62.6226 73.7802L62.0676 73.8946L61.5357 74.0409C60.8294 74.2175 60.1143 74.4495 59.3992 74.6815C58.0242 75.1539 56.6402 75.6816 55.2563 76.2094C54.5324 76.4968 53.9187 76.801 53.1165 77.0481C52.3432 77.2902 51.5575 77.4926 50.7593 77.6552C49.2133 77.9598 47.6156 78.0579 46.0355 78.0452C42.8843 77.9644 39.8128 77.3849 36.9135 76.4344C33.9912 75.452 31.2091 74.1219 28.7417 72.4141C30.1637 72.8852 31.5946 73.3008 33.0575 73.693C34.4972 74.0533 35.937 74.4136 37.3624 74.6866C40.2684 75.2409 43.2099 75.5736 46.071 75.5253C47.5087 75.5448 48.941 75.4216 50.3358 75.1792C51.0332 75.058 51.7074 74.9049 52.3905 74.6965L53.4312 74.372L54.527 74.0559C56.0355 73.6321 57.5815 73.3275 59.1009 73.1892C59.8767 73.1082 60.6755 73.0592 61.4567 73.121C61.8458 73.1611 62.2426 73.2118 62.6472 73.2732C62.8311 73.3011 63.012 73.3474 63.19 73.4123C63.3786 73.4693 63.5673 73.5263 63.7471 73.6387Z"
                fill="#27323D"
              />
              <path
                d="M63.7471 73.6387C63.3521 73.6355 63.0034 73.6961 62.6226 73.7802L62.0676 73.8946L61.5357 74.0409C60.8294 74.2175 60.1143 74.4495 59.3992 74.6815C58.0242 75.1539 56.6402 75.6816 55.2563 76.2094C54.5324 76.4968 53.9187 76.801 53.1165 77.0481C52.3432 77.2902 51.5575 77.4926 50.7593 77.6552C49.2133 77.9598 47.6156 78.0579 46.0355 78.0452C42.8843 77.9644 39.8128 77.3849 36.9135 76.4344C33.9912 75.452 31.2091 74.1219 28.7417 72.4141C30.1637 72.8852 31.5946 73.3008 33.0575 73.693C34.4972 74.0533 35.937 74.4136 37.3624 74.6866C40.2684 75.2409 43.2099 75.5736 46.071 75.5253C47.5087 75.5448 48.941 75.4216 50.3358 75.1792C51.0332 75.058 51.7074 74.9049 52.3905 74.6965L53.4312 74.372L54.527 74.0559C56.0355 73.6321 57.5815 73.3275 59.1009 73.1892C59.8767 73.1082 60.6755 73.0592 61.4567 73.121C61.8458 73.1611 62.2426 73.2118 62.6472 73.2732C62.8311 73.3011 63.012 73.3474 63.19 73.4123C63.3786 73.4693 63.5673 73.5263 63.7471 73.6387Z"
                fill="#27323D"
              />
              <path
                d="M68.0527 71.4534C67.0897 71.7045 66.1171 71.8974 65.1351 72.0322C64.1471 72.2039 63.1727 72.3493 62.212 72.4683C60.2383 72.6797 58.2657 72.8249 56.2943 72.9042C54.3247 73.031 52.3496 73.0151 50.3514 72.9673C49.3639 72.9594 48.3852 72.8961 47.4066 72.8327C46.4018 72.7559 45.4077 72.6713 44.4242 72.5789C43.4544 72.4601 42.4846 72.3414 41.4916 72.1908C40.4987 72.0401 39.5057 71.8895 38.5447 71.7153C36.5765 71.3032 34.6491 70.8122 32.7395 70.2104C30.8387 69.5532 28.9787 68.8171 27.2182 67.8123L25.9276 67.049C25.5004 66.7761 25.0945 66.4876 24.7099 66.1833C24.2809 65.8629 23.939 65.5273 23.5419 65.1833C23.1846 64.8264 22.8457 64.4724 22.5251 64.1211C24.2625 65.094 26.021 65.758 27.8843 66.296C29.7157 66.8576 31.5415 67.2765 33.4315 67.6483C34.3924 67.8224 35.3302 67.9647 36.236 68.1305C37.2058 68.2492 38.1348 68.4469 39.0815 68.5338C40.9784 68.8026 42.8919 69.0267 44.822 69.206C46.7065 69.4351 48.655 69.6172 50.5803 69.7675L56.3706 70.3054C60.2444 70.6377 64.1183 70.97 68.0527 71.4534Z"
                fill="#27323D"
              />
              <path
                d="M68.0527 71.4534C67.0897 71.7045 66.1171 71.8974 65.1351 72.0322C64.1471 72.2039 63.1727 72.3493 62.212 72.4683C60.2383 72.6797 58.2657 72.8249 56.2943 72.9042C54.3247 73.031 52.3496 73.0151 50.3514 72.9673C49.3639 72.9594 48.3852 72.8961 47.4066 72.8327C46.4018 72.7559 45.4077 72.6713 44.4242 72.5789C43.4544 72.4601 42.4846 72.3414 41.4916 72.1908C40.4987 72.0401 39.5057 71.8895 38.5447 71.7153C36.5765 71.3032 34.6491 70.8122 32.7395 70.2104C30.8387 69.5532 28.9787 68.8171 27.2182 67.8123L25.9276 67.049C25.5004 66.7761 25.0945 66.4876 24.7099 66.1833C24.2809 65.8629 23.939 65.5273 23.5419 65.1833C23.1846 64.8264 22.8457 64.4724 22.5251 64.1211C24.2625 65.094 26.021 65.758 27.8843 66.296C29.7157 66.8576 31.5415 67.2765 33.4315 67.6483C34.3924 67.8224 35.3302 67.9647 36.236 68.1305C37.2058 68.2492 38.1348 68.4469 39.0815 68.5338C40.9784 68.8026 42.8919 69.0267 44.822 69.206C46.7065 69.4351 48.655 69.6172 50.5803 69.7675L56.3706 70.3054C60.2444 70.6377 64.1183 70.97 68.0527 71.4534Z"
                fill="#27323D"
              />
              <path
                d="M72.3535 69.4641C68.3291 70.0737 64.2564 70.2787 60.2103 70.3175C56.141 70.3244 52.0663 70.1886 48.0127 69.7439C43.9822 69.3311 39.932 68.6883 35.9615 67.5468C34.0198 66.9685 32.0407 66.2711 30.2161 65.3133C29.2995 64.8621 28.4005 64.3002 27.5975 63.6676C26.7626 63.0586 26.0414 62.2682 25.5033 61.3921C25.9004 61.7361 26.2423 62.0717 26.6802 62.3368C26.8831 62.481 27.1181 62.6018 27.2979 62.7142C27.5222 62.8428 27.7387 62.9608 27.9476 63.0681C28.8352 63.5244 29.756 63.891 30.7099 64.1682C32.5876 64.7935 34.4918 65.2526 36.4689 65.6093C40.359 66.3697 44.3255 66.8295 48.3008 67.234C52.2992 67.6703 56.2834 68.0193 60.2995 68.3449C64.3157 68.6704 68.3319 68.9959 72.3535 69.4641Z"
                fill="#27323D"
              />
              <path
                d="M72.3535 69.4641C68.3291 70.0737 64.2564 70.2787 60.2103 70.3175C56.141 70.3244 52.0663 70.1886 48.0127 69.7439C43.9822 69.3311 39.932 68.6883 35.9615 67.5468C34.0198 66.9685 32.0407 66.2711 30.2161 65.3133C29.2995 64.8621 28.4005 64.3002 27.5975 63.6676C26.7626 63.0586 26.0414 62.2682 25.5033 61.3921C25.9004 61.7361 26.2423 62.0717 26.6802 62.3368C26.8831 62.481 27.1181 62.6018 27.2979 62.7142C27.5222 62.8428 27.7387 62.9608 27.9476 63.0681C28.8352 63.5244 29.756 63.891 30.7099 64.1682C32.5876 64.7935 34.4918 65.2526 36.4689 65.6093C40.359 66.3697 44.3255 66.8295 48.3008 67.234C52.2992 67.6703 56.2834 68.0193 60.2995 68.3449C64.3157 68.6704 68.3319 68.9959 72.3535 69.4641Z"
                fill="#27323D"
              />
              <path
                d="M77.5787 66.1152C75.6431 66.5593 73.679 66.8288 71.7236 67.0429C69.7867 67.2598 67.8326 67.4079 65.8611 67.4871C61.9337 67.6669 58.005 67.6194 54.0748 67.3448C53.1282 67.2579 52.1264 67.1627 51.1245 67.0674C50.1624 66.9593 49.1926 66.8406 48.2151 66.7112C46.2611 66.3864 44.3072 66.0616 42.3798 65.5706C40.4525 65.0796 38.566 64.5097 36.7149 63.7181C36.4799 63.5974 36.2681 63.5085 36.0563 63.4196L35.3746 63.0892C35.1396 62.9684 34.9047 62.8477 34.6929 62.7588L34.052 62.3495C33.6372 62.1163 33.2082 61.7958 32.8023 61.5073C32.7008 61.4351 32.5993 61.363 32.4978 61.2909L32.2254 61.0509C32.0497 60.854 31.8711 60.6756 31.6895 60.5156C33.5085 61.3307 35.3576 61.7814 37.2243 62.1214C39.1143 62.4932 40.9667 62.7459 42.8832 62.9515C44.786 63.1834 46.6948 63.3784 48.6095 63.5364C49.5881 63.5998 50.5348 63.6866 51.4815 63.7735C52.4281 63.8604 53.4068 63.9237 54.3855 63.987C58.213 64.2556 62.0636 64.556 65.9143 64.8564C69.7881 65.1887 73.6851 65.5529 77.5787 66.1152Z"
                fill="#27323D"
              />
              <path
                d="M77.5787 66.1152C75.6431 66.5593 73.679 66.8288 71.7236 67.0429C69.7867 67.2598 67.8326 67.4079 65.8611 67.4871C61.9337 67.6669 58.005 67.6194 54.0748 67.3448C53.1282 67.2579 52.1264 67.1627 51.1245 67.0674C50.1624 66.9593 49.1926 66.8406 48.2151 66.7112C46.2611 66.3864 44.3072 66.0616 42.3798 65.5706C40.4525 65.0796 38.566 64.5097 36.7149 63.7181C36.4799 63.5974 36.2681 63.5085 36.0563 63.4196L35.3746 63.0892C35.1396 62.9684 34.9047 62.8477 34.6929 62.7588L34.052 62.3495C33.6372 62.1163 33.2082 61.7958 32.8023 61.5073C32.7008 61.4351 32.5993 61.363 32.4978 61.2909L32.2254 61.0509C32.0497 60.854 31.8711 60.6756 31.6895 60.5156C33.5085 61.3307 35.3576 61.7814 37.2243 62.1214C39.1143 62.4932 40.9667 62.7459 42.8832 62.9515C44.786 63.1834 46.6948 63.3784 48.6095 63.5364C49.5881 63.5998 50.5348 63.6866 51.4815 63.7735C52.4281 63.8604 53.4068 63.9237 54.3855 63.987C58.213 64.2556 62.0636 64.556 65.9143 64.8564C69.7881 65.1887 73.6851 65.5529 77.5787 66.1152Z"
                fill="#27323D"
              />
              <path
                d="M82.4727 63.6C78.4838 63.9879 74.5017 63.9797 70.4821 63.8523C66.5177 63.7332 62.5567 63.4161 58.5671 62.9243C57.5896 62.7949 56.6043 62.6549 55.6114 62.5043C54.6184 62.3537 53.6254 62.203 52.6645 62.0289C50.7194 61.6487 48.7511 61.2366 46.8006 60.7137C44.8501 60.1908 42.9404 59.589 41.0893 58.7975C40.1406 58.3698 39.2151 57.974 38.3713 57.4204C37.4866 56.9457 36.6837 56.3132 35.9856 55.5547C36.4235 55.8197 36.8294 56.1083 37.285 56.2625C37.52 56.3833 37.7318 56.4722 37.9756 56.5376L38.6662 56.8126C39.5774 57.121 40.513 57.3954 41.4728 57.6356C43.377 58.0947 45.2989 58.443 47.2529 58.7678C49.1516 59.0842 51.1144 59.3536 53.0452 59.6465C54.0227 59.7759 55.0003 59.9052 55.9778 60.0346C56.9708 60.1853 57.9406 60.304 58.9104 60.4228L70.6817 61.8964C74.6161 62.3798 78.5417 62.9185 82.4727 63.6Z"
                fill="#27323D"
              />
              <path
                d="M82.4727 63.6C78.4838 63.9879 74.5017 63.9797 70.4821 63.8523C66.5177 63.7332 62.5567 63.4161 58.5671 62.9243C57.5896 62.7949 56.6043 62.6549 55.6114 62.5043C54.6184 62.3537 53.6254 62.203 52.6645 62.0289C50.7194 61.6487 48.7511 61.2366 46.8006 60.7137C44.8501 60.1908 42.9404 59.589 41.0893 58.7975C40.1406 58.3698 39.2151 57.974 38.3713 57.4204C37.4866 56.9457 36.6837 56.3132 35.9856 55.5547C36.4235 55.8197 36.8294 56.1083 37.285 56.2625C37.52 56.3833 37.7318 56.4722 37.9756 56.5376L38.6662 56.8126C39.5774 57.121 40.513 57.3954 41.4728 57.6356C43.377 58.0947 45.2989 58.443 47.2529 58.7678C49.1516 59.0842 51.1144 59.3536 53.0452 59.6465C54.0227 59.7759 55.0003 59.9052 55.9778 60.0346C56.9708 60.1853 57.9406 60.304 58.9104 60.4228L70.6817 61.8964C74.6161 62.3798 78.5417 62.9185 82.4727 63.6Z"
                fill="#27323D"
              />
              <path
                d="M86.4643 61.2507C84.2631 61.0586 82.0388 60.8347 79.8608 60.6745C77.6276 60.506 75.4497 60.3458 73.2859 60.2729C72.1649 60.2164 71.0902 60.2236 69.9692 60.167C68.8945 60.1742 67.7967 60.1495 66.7131 60.2121C65.6384 60.2194 64.578 60.3138 63.4944 60.3765L61.911 60.5619C61.6495 60.6073 61.3879 60.6528 61.1264 60.6982L60.365 60.8664L59.5804 61.0028C59.2317 61.0634 58.9702 61.1088 58.6535 61.1459C58.0569 61.2256 57.4633 61.2869 56.8726 61.3297C55.6875 61.3202 54.5345 61.2871 53.376 61.1114C51.0734 60.8472 48.8559 60.227 46.729 59.3937C42.548 57.6244 38.7526 55.0341 35.6382 51.8945C39.3907 54.223 43.3156 56.1803 47.4251 57.5131C49.4771 58.1081 51.5468 58.5923 53.6696 58.7441C54.1661 58.8195 54.7034 58.8159 55.2088 58.8358C55.4846 58.8776 55.7461 58.8322 55.9988 58.8421C56.2515 58.8521 56.5362 58.8385 56.7657 58.8166C57.2983 58.7839 57.8154 58.7299 58.3171 58.6547C58.5466 58.6328 58.8082 58.5873 59.0145 58.5335L59.8863 58.382C61.0284 58.1297 62.176 58.02 63.3235 57.9104C64.4623 57.8562 65.601 57.8019 66.7309 57.8031C67.8607 57.8042 69.0049 57.8927 70.0939 57.9728C71.2381 58.0612 72.3271 58.1413 73.4624 58.2852C75.6749 58.583 77.8726 58.9731 80.0556 59.4556C82.2037 59.9801 84.3517 60.5046 86.4643 61.2507Z"
                fill="#27323D"
              />
              <path
                d="M86.4643 61.2507C84.2631 61.0586 82.0388 60.8347 79.8608 60.6745C77.6276 60.506 75.4497 60.3458 73.2859 60.2729C72.1649 60.2164 71.0902 60.2236 69.9692 60.167C68.8945 60.1742 67.7967 60.1495 66.7131 60.2121C65.6384 60.2194 64.578 60.3138 63.4944 60.3765L61.911 60.5619C61.6495 60.6073 61.3879 60.6528 61.1264 60.6982L60.365 60.8664L59.5804 61.0028C59.2317 61.0634 58.9702 61.1088 58.6535 61.1459C58.0569 61.2256 57.4633 61.2869 56.8726 61.3297C55.6875 61.3202 54.5345 61.2871 53.376 61.1114C51.0734 60.8472 48.8559 60.227 46.729 59.3937C42.548 57.6244 38.7526 55.0341 35.6382 51.8945C39.3907 54.223 43.3156 56.1803 47.4251 57.5131C49.4771 58.1081 51.5468 58.5923 53.6696 58.7441C54.1661 58.8195 54.7034 58.8159 55.2088 58.8358C55.4846 58.8776 55.7461 58.8322 55.9988 58.8421C56.2515 58.8521 56.5362 58.8385 56.7657 58.8166C57.2983 58.7839 57.8154 58.7299 58.3171 58.6547C58.5466 58.6328 58.8082 58.5873 59.0145 58.5335L59.8863 58.382C61.0284 58.1297 62.176 58.02 63.3235 57.9104C64.4623 57.8562 65.601 57.8019 66.7309 57.8031C67.8607 57.8042 69.0049 57.8927 70.0939 57.9728C71.2381 58.0612 72.3271 58.1413 73.4624 58.2852C75.6749 58.583 77.8726 58.9731 80.0556 59.4556C82.2037 59.9801 84.3517 60.5046 86.4643 61.2507Z"
                fill="#27323D"
              />
              <path
                d="M90.9186 58.124C88.6555 58.3198 86.3957 58.3175 84.136 58.3151C81.919 58.2815 79.6817 58.1974 77.4242 58.063C72.9579 57.726 68.4861 57.2462 64.0818 56.5213C59.6543 55.7645 55.2533 54.8415 50.9464 53.5072C48.7609 52.8635 46.6483 52.1174 44.5622 51.2051C43.5352 50.7372 42.517 50.2139 41.5309 49.667C41.0378 49.3936 40.5536 49.0648 40.0925 48.7679C39.8344 48.6152 39.6083 48.4391 39.3822 48.2629C39.1638 48.0973 38.9377 47.9212 38.7039 47.7344C40.8308 48.5678 42.9468 49.1158 45.1268 49.6167C47.2517 50.1093 49.4174 50.523 51.5832 50.9366C55.9147 51.764 60.2959 52.457 64.6451 53.1735C69.0174 53.9219 73.4218 54.6468 77.7621 55.4188C82.1487 56.2545 86.5442 57.0348 90.9186 58.124Z"
                fill="#27323D"
              />
              <path
                d="M90.9186 58.124C88.6555 58.3198 86.3957 58.3175 84.136 58.3151C81.919 58.2815 79.6817 58.1974 77.4242 58.063C72.9579 57.726 68.4861 57.2462 64.0818 56.5213C59.6543 55.7645 55.2533 54.8415 50.9464 53.5072C48.7609 52.8635 46.6483 52.1174 44.5622 51.2051C43.5352 50.7372 42.517 50.2139 41.5309 49.667C41.0378 49.3936 40.5536 49.0648 40.0925 48.7679C39.8344 48.6152 39.6083 48.4391 39.3822 48.2629C39.1638 48.0973 38.9377 47.9212 38.7039 47.7344C40.8308 48.5678 42.9468 49.1158 45.1268 49.6167C47.2517 50.1093 49.4174 50.523 51.5832 50.9366C55.9147 51.764 60.2959 52.457 64.6451 53.1735C69.0174 53.9219 73.4218 54.6468 77.7621 55.4188C82.1487 56.2545 86.5442 57.0348 90.9186 58.124Z"
                fill="#27323D"
              />
              <path
                d="M93.6607 56.3549C89.8665 55.524 86.1133 54.6141 82.36 53.7043C78.6251 52.7972 74.895 51.9192 71.1697 51.0703C67.4307 50.2478 63.6829 49.4806 59.9262 48.7688C56.1375 48.0806 52.3631 47.4796 48.4927 46.9492C52.357 46.4572 56.3071 46.489 60.213 46.7978C64.0548 47.1536 67.9395 47.7713 71.7336 48.6023C75.5266 49.4992 79.264 50.5675 82.9457 51.807C84.7594 52.4793 86.573 53.1517 88.3778 53.8795C90.1505 54.6308 91.9465 55.4139 93.6607 56.3549Z"
                fill="#27323D"
              />
              <path
                d="M93.6607 56.3549C89.8665 55.524 86.1133 54.6141 82.36 53.7043C78.6251 52.7972 74.895 51.9192 71.1697 51.0703C67.4307 50.2478 63.6829 49.4806 59.9262 48.7688C56.1375 48.0806 52.3631 47.4796 48.4927 46.9492C52.357 46.4572 56.3071 46.489 60.213 46.7978C64.0548 47.1536 67.9395 47.7713 71.7336 48.6023C75.5266 49.4992 79.264 50.5675 82.9457 51.807C84.7594 52.4793 86.573 53.1517 88.3778 53.8795C90.1505 54.6308 91.9465 55.4139 93.6607 56.3549Z"
                fill="#27323D"
              />
              <path
                d="M97.3607 53.8826C95.069 53.9038 92.7984 53.6161 90.5135 53.2411C88.2374 52.8107 86.0022 52.3014 83.8024 51.5704C83.2364 51.3994 82.7025 51.2049 82.1686 51.0105C81.6133 50.8316 81.0716 50.6265 80.5436 50.3951L78.9186 49.7797L77.3433 49.03C77.0764 48.9328 76.8414 48.812 76.5513 48.6829L75.7681 48.2803L74.2568 47.4836L72.7313 46.5996L71.9889 46.1181C71.7308 45.9654 71.5279 45.8211 71.2929 45.7004C70.3708 45.1065 69.4078 44.5916 68.4128 44.1001C67.4421 43.5746 66.447 43.0831 65.4277 42.6258C64.4239 42.1898 63.388 41.7773 62.3521 41.3648C61.2997 40.9971 60.2443 40.6478 59.1859 40.317C54.9021 39.0145 50.4658 38.3132 45.9143 38.332C50.3814 37.2505 55.1589 37.4078 59.6934 38.3794C61.9606 38.8653 64.1924 39.5727 66.3745 40.4144C68.5245 41.2797 70.616 42.3347 72.6258 43.5476C72.8839 43.7002 73.142 43.8529 73.3681 44.029L74.0642 44.4467L75.4794 45.314L76.9354 46.1024L77.6866 46.5285L78.4147 46.9226L79.9027 47.6875L81.4317 48.3734C81.9016 48.6149 82.4356 48.8094 82.9607 49.0593C83.4626 49.2773 83.9645 49.4954 84.4665 49.7134C86.5614 50.5703 88.674 51.3164 90.8186 52.0389C92.9662 52.7431 95.1469 53.3576 97.3607 53.8826Z"
                fill="#27323D"
              />
              <path
                d="M97.3607 53.8826C95.069 53.9038 92.7984 53.6161 90.5135 53.2411C88.2374 52.8107 86.0022 52.3014 83.8024 51.5704C83.2364 51.3994 82.7025 51.2049 82.1686 51.0105C81.6133 50.8316 81.0716 50.6265 80.5436 50.3951L78.9186 49.7797L77.3433 49.03C77.0764 48.9328 76.8414 48.812 76.5513 48.6829L75.7681 48.2803L74.2568 47.4836L72.7313 46.5996L71.9889 46.1181C71.7308 45.9654 71.5279 45.8211 71.2929 45.7004C70.3708 45.1065 69.4078 44.5916 68.4128 44.1001C67.4421 43.5746 66.447 43.0831 65.4277 42.6258C64.4239 42.1898 63.388 41.7773 62.3521 41.3648C61.2997 40.9971 60.2443 40.6478 59.1859 40.317C54.9021 39.0145 50.4658 38.3132 45.9143 38.332C50.3814 37.2505 55.1589 37.4078 59.6934 38.3794C61.9606 38.8653 64.1924 39.5727 66.3745 40.4144C68.5245 41.2797 70.616 42.3347 72.6258 43.5476C72.8839 43.7002 73.142 43.8529 73.3681 44.029L74.0642 44.4467L75.4794 45.314L76.9354 46.1024L77.6866 46.5285L78.4147 46.9226L79.9027 47.6875L81.4317 48.3734C81.9016 48.6149 82.4356 48.8094 82.9607 49.0593C83.4626 49.2773 83.9645 49.4954 84.4665 49.7134C86.5614 50.5703 88.674 51.3164 90.8186 52.0389C92.9662 52.7431 95.1469 53.3576 97.3607 53.8826Z"
                fill="#27323D"
              />
              <path
                d="M102.489 49.7212C101.598 49.6427 100.742 49.3426 99.9175 49.019C99.0615 48.7189 98.2782 48.3163 97.495 47.9138C95.9286 47.1086 94.412 46.1692 92.9913 45.1592C92.6317 44.9344 92.249 44.6778 91.8982 44.3976L90.8372 43.6124C90.1908 43.0604 89.4574 42.5235 88.8111 41.9714C88.1736 41.364 87.4953 40.8355 86.8578 40.2281C86.2204 39.6206 85.5829 39.0132 84.9455 38.4058L84.9223 38.3739L84.844 38.3336L84.7051 38.1423L84.5341 37.9745C84.4202 37.8627 84.2985 37.7402 84.1691 37.607C83.9287 37.3436 83.6794 37.1355 83.407 36.8956C82.8942 36.3922 82.3493 35.9123 81.7957 35.4878C80.674 34.5516 79.5434 33.6707 78.34 32.8923L77.879 32.5954L77.4179 32.2984C77.0815 32.1055 76.8322 31.8975 76.4958 31.7046C76.167 31.5223 75.8567 31.3428 75.5648 31.1661C75.2283 30.9732 74.9151 30.8122 74.5786 30.6193C73.2792 29.9114 71.9069 29.306 70.5203 28.6133C72.0683 28.6495 73.5986 28.7965 75.1255 29.1417C76.6524 29.4868 78.1208 30.0217 79.5714 30.6673C80.9989 31.2811 82.3678 32.0846 83.6638 32.9906C84.3095 33.429 84.9492 33.9044 85.583 34.4167C85.8786 34.6885 86.183 34.9049 86.4786 35.1768C86.6234 35.3311 86.7682 35.4855 86.9131 35.6399L87.1392 35.8161C87.2086 35.9117 87.2781 36.0074 87.3796 36.0795L87.2781 36.0074C87.9001 36.5936 88.5221 37.1797 89.1442 37.7659L91.0102 39.5244L94.6872 43.0331C95.9312 44.2055 97.1966 45.3621 98.4833 46.5031C99.7439 47.6307 101.028 48.7901 102.489 49.7212Z"
                fill="#27323D"
              />
              <path
                d="M102.489 49.7212C101.598 49.6427 100.742 49.3426 99.9175 49.019C99.0615 48.7189 98.2782 48.3163 97.495 47.9138C95.9286 47.1086 94.412 46.1692 92.9913 45.1592C92.6317 44.9344 92.249 44.6778 91.8982 44.3976L90.8372 43.6124C90.1908 43.0604 89.4574 42.5235 88.8111 41.9714C88.1736 41.364 87.4953 40.8355 86.8578 40.2281C86.2204 39.6206 85.5829 39.0132 84.9455 38.4058L84.9223 38.3739L84.844 38.3336L84.7051 38.1423L84.5341 37.9745C84.4202 37.8627 84.2985 37.7402 84.1691 37.607C83.9287 37.3436 83.6794 37.1355 83.407 36.8956C82.8942 36.3922 82.3493 35.9123 81.7957 35.4878C80.674 34.5516 79.5434 33.6707 78.34 32.8923L77.879 32.5954L77.4179 32.2984C77.0815 32.1055 76.8322 31.8975 76.4958 31.7046C76.167 31.5223 75.8567 31.3428 75.5648 31.1661C75.2283 30.9732 74.9151 30.8122 74.5786 30.6193C73.2792 29.9114 71.9069 29.306 70.5203 28.6133C72.0683 28.6495 73.5986 28.7965 75.1255 29.1417C76.6524 29.4868 78.1208 30.0217 79.5714 30.6673C80.9989 31.2811 82.3678 32.0846 83.6638 32.9906C84.3095 33.429 84.9492 33.9044 85.583 34.4167C85.8786 34.6885 86.183 34.9049 86.4786 35.1768C86.6234 35.3311 86.7682 35.4855 86.9131 35.6399L87.1392 35.8161C87.2086 35.9117 87.2781 36.0074 87.3796 36.0795L87.2781 36.0074C87.9001 36.5936 88.5221 37.1797 89.1442 37.7659L91.0102 39.5244L94.6872 43.0331C95.9312 44.2055 97.1966 45.3621 98.4833 46.5031C99.7439 47.6307 101.028 48.7901 102.489 49.7212Z"
                fill="#27323D"
              />
              <path
                d="M59.006 75.8976C64.8719 72.9571 70.6078 69.7698 76.2279 66.423C81.848 63.0763 87.3292 59.5383 92.6108 55.6578C95.2588 53.7613 97.8142 51.7372 100.268 49.6409C101.493 48.5782 102.683 47.4915 103.84 46.3807C105.018 45.2543 106.134 44.1088 107.186 42.9444C108.226 41.7402 109.243 40.5042 110.126 39.2195C111.01 37.9348 111.792 36.578 112.376 35.2196C112.937 33.8293 113.301 32.4374 113.403 31.0909C113.45 29.7361 113.181 28.4184 112.632 27.2569L116.2 25.6134C116.959 27.4027 117.256 29.4339 117.065 31.3344C116.874 33.2348 116.251 35.013 115.457 36.6233C114.718 38.242 113.721 39.708 112.687 41.0549C111.643 42.4572 110.507 43.7319 109.325 44.9428C108.156 46.1275 106.938 47.2668 105.671 48.3609C104.419 49.4761 103.129 50.5383 101.8 51.5473C99.2123 53.5949 96.4772 55.5066 93.7046 57.2992C88.2057 60.948 82.4841 64.2226 76.7108 67.2908C70.9054 70.3824 65.0163 73.2911 59.0291 75.9295L59.006 75.8976Z"
                fill="#27323D"
              />
              <path
                d="M59.006 75.8976C64.8719 72.9571 70.6078 69.7698 76.2279 66.423C81.848 63.0763 87.3292 59.5383 92.6108 55.6578C95.2588 53.7613 97.8142 51.7372 100.268 49.6409C101.493 48.5782 102.683 47.4915 103.84 46.3807C105.018 45.2543 106.134 44.1088 107.186 42.9444C108.226 41.7402 109.243 40.5042 110.126 39.2195C111.01 37.9348 111.792 36.578 112.376 35.2196C112.937 33.8293 113.301 32.4374 113.403 31.0909C113.45 29.7361 113.181 28.4184 112.632 27.2569L116.2 25.6134C116.959 27.4027 117.256 29.4339 117.065 31.3344C116.874 33.2348 116.251 35.013 115.457 36.6233C114.718 38.242 113.721 39.708 112.687 41.0549C111.643 42.4572 110.507 43.7319 109.325 44.9428C108.156 46.1275 106.938 47.2668 105.671 48.3609C104.419 49.4761 103.129 50.5383 101.8 51.5473C99.2123 53.5949 96.4772 55.5066 93.7046 57.2992C88.2057 60.948 82.4841 64.2226 76.7108 67.2908C70.9054 70.3824 65.0163 73.2911 59.0291 75.9295L59.006 75.8976Z"
                fill="#27323D"
              />
            </svg>
          </div>
        </div>
        <div className={css.sectionrightcontent}>
          <div className={css.sectionrightcontenttitle}>
            Book A Live Demo and get <br />
            <span className={css.sectionrightcontenttitle2}>15% off any booking.</span>
          </div>
          <div className={css.sectionrightcontenttext}>
            Want to learn more? Our pleasure! Book a demo with our founder and dedicate experience
            coordinators and get 15% off your first booking.
          </div>
          <PrimaryLightButton>Give it a try</PrimaryLightButton>
        </div>
      </div>
    </div>
  );
};

export default SectionLiveDemo;
